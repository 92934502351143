<div class="pair-list-item-container {{hasArrivalAndDepartureLeg ? (pair.departureLeg.atd ? 'ATD' : pair.status) : 'DISABLED'}} " *ngIf="pair">

  <div class="flight-info-data-container">

    <app-icon-text-item-small iconHeader="icon-plane-arriving"
                              [text]="pair?.arrivalLeg?.airlineDesignator + pair?.arrivalLeg?.flightNumber">
    </app-icon-text-item-small>

    <app-icon-text-item-small textHeader="GATE"
                              [text]="(pair?.arrivalLeg) ? pair?.arrivalLeg?.arrivalAcStand : pair?.departureLeg?.departureAcStand">
    </app-icon-text-item-small>

    <app-icon-text-item-small [textHeader]="isArrivalLegAta ? 'ATA' : isArrivalLegEta ? 'ETA' : 'STA'"
                              [text]="(isArrivalLegAta ? pair?.arrivalLeg?.ata : isArrivalLegEta ? pair?.arrivalLeg?.eta : pair?.arrivalLeg?.sta) | date:'HH:mm':timezone"
                              [indicator]="0 !== calcDateDifferenceInDays(pair?.dateOfOperation, pair.arrivalLeg?.ata ?? pair.arrivalLeg?.eta ?? pair.arrivalLeg?.sta, timezone)"
                              indicatorType="text"
                              [indicatorText]="calcDateDifferenceString(pair?.dateOfOperation, pair.arrivalLeg?.ata ?? pair.arrivalLeg?.eta ?? pair.arrivalLeg?.sta, timezone)">
    </app-icon-text-item-small>

    <app-icon-text-item-small iconHeader="icon-icons8-synchronize"
                              [text]="turnaroundTime ? (turnaroundTime | minutesToTime) : '-'"
                              [taInProgress]="pair?.turnaround?.currentState === 'IN_PROGRESS' || pair?.turnaround?.currentState === 'RESUMED'">
    </app-icon-text-item-small>

    <app-icon-text-item-small iconHeader="icon-plane-departing"
                              [text]="pair?.departureLeg?.airlineDesignator + pair?.departureLeg?.flightNumber">
    </app-icon-text-item-small>

    <app-icon-text-item-small textHeader="STD"
                              [text]="pair?.departureLeg?.std | date:'HH:mm':timezone"
                              [indicator]="0 !== calcDateDifferenceInDays(pair?.departureLeg?.std, pair?.dateOfOperation, timezone)"
                              indicatorType="text"
                              [indicatorText]="calcDateDifferenceString(pair?.departureLeg?.std, pair?.dateOfOperation, timezone)">
    </app-icon-text-item-small>

    <app-icon-text-item-small *ngIf="pair?.departureLeg?.atd; else etd" textHeader="ATD"
                              [text]="pair?.departureLeg?.atd | date:'HH:mm':timezone"
                              [indicator]="0 !== calcDateDifferenceInDays(pair?.departureLeg?.atd, pair?.dateOfOperation, timezone)"
                              indicatorType="text"
                              [indicatorText]="calcDateDifferenceString(pair?.departureLeg?.atd, pair?.dateOfOperation, timezone)">
    </app-icon-text-item-small>

    <ng-template #etd>
      <app-icon-text-item-small textHeader="ETD"
                                [text]="pair?.departureLeg?.etd | date:'HH:mm':timezone"
                                [indicator]="0 !== calcDateDifferenceInDays(pair?.dateOfOperation, pair?.departureLeg?.etd, timezone)"
                                indicatorType="text"
                                [indicatorText]="calcDateDifferenceString(pair?.dateOfOperation, pair?.departureLeg?.etd, timezone)">
      </app-icon-text-item-small>
    </ng-template>
  </div>
</div>
