import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { HttpClient } from '@angular/common/http';
import { IMemoModel } from '../shared/models/memo.model';
import { Searchable } from '../shared/utils/searchable.type';

@Injectable({
  providedIn: 'root'
})
export class MemosService {

  constructor(private httpClient: HttpClient) { }

  deleteMemo(memoId: number): Observable<any> {
    return this.httpClient.delete(`${environment.api_base_url}memos/${memoId}`, { headers: { authorization : '123'}}).pipe(
      catchError(() => {
        return of({});
      })
    );
  }

  getMemos(filter: Searchable<IMemoModel> = { isActive: true }): Observable<IMemoModel[]> {
    return this.httpClient.post(`${environment.api_base_url}memos/search`, filter, { headers: { authorization : '123'}}).pipe(
      map((item: IResponse<IMemoModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  saveMemo(memo: IMemoModel): Observable<IMemoModel> {
    let obs;
    if (memo.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}memos/${memo.id}`, memo, { headers: { authorization : '123'}})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}memos/create`, memo, { headers: { authorization : '123'}})
    }
    return obs.pipe(
      map((item: IResponse<IMemoModel>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || null;
      }),
      catchError(() => {
        return of(null);
      })
    );
  }
}
