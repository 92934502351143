import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../services/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterService } from '../../services/filter.service';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { TimeService } from '../../services/time.service';
import { RoutableModel } from '../../shared/models/routable.model';
import { UserMenuComponent } from '../../shared/components/user-menu/user-menu.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() user: any;
  @Input() activeComponent: RoutableModel;
  time: Date;
  homeIcon = faHome;

  unsubscribe$ = new Subject();

  constructor(
    private modalService: NgbModal,
    private auth: AngularFireAuth,
    private userService: UserService,
    private filterService: FilterService,
    private router: Router,
    private timeService: TimeService,
    private offcanvasService: NgbOffcanvas,
    private loc: Location,
  ) {
  }

  ngOnInit(): void {
    this.timeService.getTimeObservable().pipe(takeUntil(this.unsubscribe$)).subscribe((time) => {
      this.time = time;
    })
  }

  openUserCard() {
    this.offcanvasService.open(UserMenuComponent, { position: 'bottom', panelClass: 'user-menu-canvas', animation: true });
  }

  closeUserCard() {
    this.offcanvasService.dismiss();
  }


  signOut() {
    this.auth.signOut()
      .then(response => {
        console.log('sign out repsonse: ', response);
        this.userService.setUser(null);
      })
      .catch(error => console.log('sign our error: ', error));
  }

  home() {
    this.router.navigateByUrl('/turnarounds');
  }

  goBack() {
    if (!this.activeComponent) {
      return;
    }
    if (this.activeComponent?.backUrl === null) {
      this.loc.back();
      return;
    }
    this.router.navigateByUrl(this.activeComponent?.backUrl);
  }
}
