<div class="menu-container mt-2">

  <div class="back-button-container">
    <button class="btn btn-primary" routerLink="/turnarounds/"><fa-icon [icon]="faLeftArrow"></fa-icon></button>
  </div>
  <div class="other-actions-container">
    <div class="new-msg-container">
      <button class="btn btn-primary" (click)="openViewPairChat()" [disabled]="newMessageDisabled">Chat</button>
    </div>
    <div class="new-memo-container">
      <button class="btn btn-primary" (click)="openViewMemo()">Memos</button>
    </div>
  </div>
</div>

<ngb-alert class="top-alert text-center" *ngIf="noUsersAlert" (closed)="noUsersAlert = false">No duty managers found for airport {{ location }}</ngb-alert>
