import { Component, OnInit } from '@angular/core';
import { IUser } from '../../models/user.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserRoles } from '../../constants/user-roles.constants';

@Component({
  selector: 'app-select-user-to-chat',
  templateUrl: './select-user-to-chat.component.html',
  styleUrls: ['./select-user-to-chat.component.scss']
})
export class SelectUserToChatComponent implements OnInit {

  userRoleObject = UserRoles;
  userList: IUser[];
  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  protected readonly UserRoles = UserRoles;
}
