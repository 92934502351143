import {ITurnaround} from '../models/turnaround.model';
import * as dayjs from 'dayjs';
import {TurnaroundStatus} from '../constants/turnaround-status.constants';
import * as firebase from 'firebase/firestore';

export class RemainingTimeCalculator {
  public static calcRemainingTime(tod: Date): number {
    if (!tod || !dayjs(tod).isValid()) {
      return 0;
    }

    return this.calcDurationInMinutesFromTimeAToTimeB(new Date(), dayjs(tod).toDate());
  }

  public static calcTotalTime(turnaround: ITurnaround, tod: Date) {

    if (!turnaround || !turnaround.currentState || !tod || !dayjs(tod).isValid()) {
      return 0;
    }

    let timeA = null;
    const timeB = tod;

    switch (turnaround.currentState) {
      case TurnaroundStatus.UPCOMING:
        return 0;
      case TurnaroundStatus.IN_PROGRESS:
        timeA = turnaround.lastStartedAt;
        break;
      case TurnaroundStatus.PAUSED:
        timeA = new Date();
        break;
      case TurnaroundStatus.RESUMED:
        timeA = turnaround.lastResumedAt;
        break;
      case TurnaroundStatus.FINISHED:
        return 0;
      default:
        return 0;
    }

    if (timeA instanceof firebase.Timestamp) {
      timeA = timeA.toDate();
    }
    const resDuration: number = this.calcDurationInMinutesFromTimeAToTimeB(timeA, timeB);

    const passedTime = this.calculatePassedTime(turnaround);

    // console.log('resulting time without passed time is: ', resDuration);
    //
    // console.log('passed time is: ', passedTime);
    //
    // console.log('total time is: ', resDuration + (passedTime || 0));

    return resDuration + (passedTime || 0);
  }

  private static calculatePassedTime(turnaround: ITurnaround): number {
    let totalTime = 0;
    let startedAt: Date;
    for (const event of turnaround.turnaroundEvents) {
      if (event.newState === TurnaroundStatus.IN_PROGRESS || event.newState === TurnaroundStatus.RESUMED) {
        startedAt = (event.datetime as unknown as firebase.Timestamp).toDate();
      } else {
        if (startedAt) {
          totalTime += dayjs((event.datetime as unknown as firebase.Timestamp).toDate()).diff(startedAt, 'minutes');
        }
      }
    }
    if(totalTime === 0 && startedAt) {
      totalTime = dayjs().diff(startedAt, 'minutes');
    }
    return totalTime;
  }

  private static calcDurationInMinutesFromTimeAToTimeB(timeA: Date, timeB: Date): number {
    if (!timeA || !timeB) {
      return 0;
    }

    const timeAMoment = dayjs(timeA);
    const timeBMoment = dayjs(timeB);

    return timeBMoment.diff(timeAMoment, 'minutes');
  }
}
