import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IPairDetail } from '../../models/pair-detail.model';
import { IUser } from '../../models/user.model';
import { UserRoles } from '../../constants/user-roles.constants';

@Component({
  selector: 'app-chat-dialog',
  templateUrl: './chat-dialog.component.html',
  styleUrls: ['./chat-dialog.component.scss']
})
export class ChatDialogComponent implements OnInit {

  title = "Chat";
  showInfo = true;
  showInfoActive = false;
  data: IPairDetail;
  user: IUser;
  userRoleObject = UserRoles;
  constructor(private activeModal: NgbActiveModal) { }
  closeModal(reason: string) {
    this.activeModal.dismiss(reason);
  }

  ngOnInit(): void {
  }

  infoClicked() {
  this.showInfoActive = !this.showInfoActive;
  }

}
