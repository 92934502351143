import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FirebaseError } from '@angular/fire/app';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  error: string = '';
  message: string = '';
  email: string = '';
  isBusy: boolean = false;
  constructor(private auth: AngularFireAuth) { }

  ngOnInit(): void {
  }

  async reset() {
    const res = await this.auth.sendPasswordResetEmail(this.email).catch((err) => {
      console.error('Error:', err);
      if (err instanceof FirebaseError) {
        switch(err.code) {
          case 'auth/user-not-found':
            this.error = 'This e-mail was not found in our database.';
            break;
          default:
            this.error = 'An error has occurred.';
            break;
        }
      }
      return null;
    });
    if (res !== null) {
      this.message = 'Please check your e-mail to continue with password reset process.';
      this.error = '';
    } else {
      this.message = '';
    }
  }

}
