import { Pipe, PipeTransform } from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Pipe({
  name: 'minutesToTime'
})
export class MinutesToTimePipe extends DecimalPipe implements PipeTransform {

  transform(minutes: number): string | any {
    if (!minutes || minutes === 0) {
      return '00:00';
    }
    const m = minutes % 60;
    const h = (minutes - m) / 60;
    return super.transform(h, '2.') + ':' + super.transform(m, '2.0-0');
  }
}
