import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ITurnaround} from '../../../../shared/models/turnaround.model';
import {TurnaroundStatus} from '../../../../shared/constants/turnaround-status.constants';
import {RemainingTimeCalculator} from '../../../../shared/utils/remaining-time.calculator';
import {interval, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-remaining-time',
  templateUrl: './remaining-time.component.html',
  styleUrls: ['./remaining-time.component.scss']
})
export class RemainingTimeComponent implements OnInit, OnDestroy {

  @Input() turnaroundData: ITurnaround;
  @Input() tod: Date;
  taState = TurnaroundStatus;
  remainingTimeInMinutes = 0;
  totalTimeInMinutes = 0;
  percentage = 0;

  unsubscribe$ = new Subject();

  constructor() {
  }

  ngOnInit(): void {

    this.updateRemainingTime();
    //console.log('TOD:', this.tod);
    //console.log('Turnaround data:', this.turnaroundData);
    interval(10000).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.updateRemainingTime();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  updateRemainingTime() {
    this.remainingTimeInMinutes = RemainingTimeCalculator.calcRemainingTime(this.tod);
    this.totalTimeInMinutes = RemainingTimeCalculator.calcTotalTime(this.turnaroundData, this.tod);

    // console.log('Remaining time in minutes:', this.remainingTimeInMinutes);
    // console.log('Total time in minutes:', this.totalTimeInMinutes);

    if (this.totalTimeInMinutes === 0) {
      this.percentage = 0;
      return;
    }

    this.percentage = this.remainingTimeInMinutes / this.totalTimeInMinutes;
  }

}
