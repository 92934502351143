<div class="page-container">
  <div class="row filter-container">
    <div style="text-align: right;" class="header-funnel me-2" (click)="openFilterModal()">
      Filter
    </div>
  </div>
  <div class="list-container mt-3">
    <app-turnarounds-pair-list [pairList]="pairList" [isBusy]="isBusy"></app-turnarounds-pair-list>
  </div>
  <div class="pagination">
    <button class="btn button-blue" [disabled]="page === 1 || isBusy" (click)="previousPage()">Previous</button>
    <button class="btn button-blue" [disabled]="!hasNextPage || isBusy" (click)="nextPage()">Next</button>
  </div>
</div>
