<div class="pair-list-item-container {{hasArrivalAndDepartureLeg ? ((isOverview(pair) ? pair.atd : pair.departureLeg.atd) ? 'ATD' : pair.status) : 'DISABLED'}} " *ngIf="pair">

  <div class="flight-info-data-container">

    <app-icon-text-item-small iconHeader="icon-plane-arriving"
                              [text]="isOverview(pair) ? pair.airlineDesignator + pair.flightNumberArriving : pair?.arrivalLeg?.airlineDesignator + pair?.arrivalLeg?.flightNumber">
    </app-icon-text-item-small>

    <app-icon-text-item-small textHeader="GATE"
                              [text]="isOverview(pair) ? pair?.arrivalAircraftStand : (pair?.arrivalLeg) ? pair?.arrivalLeg?.arrivalAcStand : pair?.departureLeg?.departureAcStand">
    </app-icon-text-item-small>

    <app-icon-text-item-small [textHeader]="isArrivalLegAta ? 'ATA' : isArrivalLegLanding ? 'LND' : isArrivalLegEta ? 'ETA' : 'STA'"
                              [text]="(isArrivalLegAta ? (isOverview(pair) ? pair.ata : pair?.arrivalLeg?.ata) : isArrivalLegLanding ? (isOverview(pair) ? pair.landingTime : pair?.arrivalLeg?.landingTime) : isArrivalLegEta ? (isOverview(pair) ? pair.eta : pair?.arrivalLeg?.eta) : (isOverview(pair) ? pair.sta : pair?.arrivalLeg?.sta)) | date:'HH:mm':timezone"
                              [indicator]="0 !== calcDateDifferenceInDays(pair?.dateOfOperation,  getLatestArrivalTime(pair), timezone)"
                              indicatorType="text"
                              [indicatorText]="calcDateDifferenceString(pair?.dateOfOperation, getLatestArrivalTime(pair), timezone)">
    </app-icon-text-item-small>

    <app-icon-text-item-small iconHeader="icon-icons8-synchronize"
                              [text]="turnaroundTime ? (turnaroundTime | minutesToTime) : '-'"
                              [taInProgress]="isPairOverview(pair) ? pair.turnaroundInProgress : (pair?.turnaround?.currentState === 'IN_PROGRESS' || pair?.turnaround?.currentState === 'RESUMED')">
    </app-icon-text-item-small>

    <app-icon-text-item-small iconHeader="icon-plane-departing"
                              [text]="isPairOverview(pair) ? pair.airlineDesignator + pair.flightNumberDeparting : pair?.departureLeg?.airlineDesignator + pair?.departureLeg?.flightNumber">
    </app-icon-text-item-small>

    <app-icon-text-item-small textHeader="STD"
                              [text]="(isPairOverview(pair) ? pair.std : pair?.departureLeg?.std) | date:'HH:mm':timezone"
                              [indicator]="0 !== calcDateDifferenceInDays((isPairOverview(pair) ? pair.std : pair?.departureLeg?.std), pair?.dateOfOperation, timezone)"
                              indicatorType="text"
                              [indicatorText]="calcDateDifferenceString((isPairOverview(pair) ? pair.std : pair?.departureLeg?.std), pair?.dateOfOperation, timezone)">
    </app-icon-text-item-small>

    <app-icon-text-item-small *ngIf="(isPairOverview(pair) ? pair.atd : pair?.departureLeg?.atd); else etd" textHeader="ATD"
                              [text]="(isPairOverview(pair) ? pair.atd : pair?.departureLeg?.atd) | date:'HH:mm':timezone"
                              [indicator]="0 !== calcDateDifferenceInDays((isPairOverview(pair) ? pair.atd : pair?.departureLeg?.atd), pair?.dateOfOperation, timezone)"
                              indicatorType="text"
                              [indicatorText]="calcDateDifferenceString((isPairOverview(pair) ? pair.atd : pair?.departureLeg?.atd), pair?.dateOfOperation, timezone)">
    </app-icon-text-item-small>

    <ng-template #etd>
      <app-icon-text-item-small textHeader="ETD"
                                [text]="(isPairOverview(pair) ? pair.etd : pair?.departureLeg?.etd) | date:'HH:mm':timezone"
                                [indicator]="0 !== calcDateDifferenceInDays(pair?.dateOfOperation, (isPairOverview(pair) ? pair.etd : pair?.departureLeg?.etd), timezone)"
                                indicatorType="text"
                                [indicatorText]="calcDateDifferenceString(pair?.dateOfOperation, (isPairOverview(pair) ? pair.etd : pair?.departureLeg?.etd), timezone)">
      </app-icon-text-item-small>
    </ng-template>
  </div>
</div>
