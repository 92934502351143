<div *ngIf="user" class="list-item-container dark-blue" [ngClass]="{'selected': selectedMessageGroupKey === this.chatGroupId, 'not-selected': selectedMessageGroupKey !== this.chatGroupId}" (click)="showMessageGroupDetails()">
  <div class="taState-indicator-container m-2">
    <div *ngIf="user?.photoUrl; else noPhoto" class="icon-circle-container">
      <img src="{{user.photoUrl}}" alt="User Photo" width="50" height="50">
    </div>
    <ng-template #noPhoto>
      <app-user-photo [user]="user" [size]="50"></app-user-photo>
    </ng-template>
  </div>
  <div class="content-container">
    <div class="message-group-title" [ngClass]="{ 'fw-bold': numberOfUnreadMessages > 0 }">
      {{user?.firstname}} {{user?.lastname}}
    </div>
    <div class="user-role" [ngClass]="{ 'fw-bold': numberOfUnreadMessages > 0 }">
      {{user?.role | i18nSelect: userRoleObject.DISPLAY_TITLE_MAP }}
    </div>
    <div class="message-group-last-message" [ngClass]="{ deleted: chatGroup.lastMessage && chatGroup.lastMessage.isActive === false }" >
      {{ chatGroup?.lastMessage?.content ? (chatGroup.lastMessage.isActive ? chatGroup.lastMessage.content : '*Message Deleted*') : '-' }}
    </div>
  </div>
  <div class="last-datetime-container m-2">
    <div class="spacer-10"></div>
    <ng-container *ngIf="chatGroup?.lastMessage?.lastChangedAt">
      <span class="roboto-font dark-blue" [ngClass]="{ 'fw-bold': numberOfUnreadMessages > 0 }" *ngIf="isToday(chatGroup.lastMessage?.lastChangedAt)">{{chatGroup.lastMessage?.lastChangedAt | date: 'HH:mm'}}</span>
      <span class="roboto-font dark-blue" [ngClass]="{ 'fw-bold': numberOfUnreadMessages > 0 }" *ngIf="!isToday(chatGroup.lastMessage?.lastChangedAt)">{{chatGroup.lastMessage?.lastChangedAt | date: 'dd.MM.yy'}}</span>
    </ng-container>
    <ng-container *ngIf="!chatGroup?.lastMessage?.lastChangedAt">
      <span class="roboto-font dark-blue">-</span>
    </ng-container>
    <div class="icon-proceed-container">
      <i class="demo-icon icon-icons8-forward dark-blue"></i>
    </div>
  </div>
</div>
