import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-chat-session-message',
  templateUrl: './chat-session-message.component.html',
  styleUrls: ['./chat-session-message.component.scss']
})
export class ChatSessionMessageComponent implements OnInit {

  @Input() isOwnMessage: boolean;
  @Input() text: string;
  @Input() firstname: string;

  constructor() { }

  ngOnInit(): void {
  }

}
