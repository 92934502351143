<a [routerLink]="routerLink" routerLinkActive="active" class="no-focus">
  <div class="item-container">
    <ng-container *ngIf="indicatorText?.length">
      <app-indicator-text [text]="indicatorText" width="16px" height="16px" marginTop="-4px" marginRight="-8px"></app-indicator-text>
    </ng-container>

<!--    <i class="demo-icon {{iconClass}} tabbar-icon"></i>-->
<!--    <fa-icon [icon]="homeIcon" [size]="'2x'" [styles]="{ 'color': '#002060' }"></fa-icon>-->
    <img *ngIf="icon?.includes('.svg' || '.png' || '.jpg'); else divImg" alt="Menu Image" src="{{icon}}">
    <ng-template #divImg>
      <div class="{{icon}}" [ngStyle]="{ width: iconSize + 'px', height: iconSize + 'px' }"></div>
    </ng-template>
  </div>
</a>
