import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {

  destroy$ = new Subject();
  activeComponent = new BehaviorSubject<any>(null);
  constructor(public userService: UserService) { }


  ngOnInit(): void {  }

  ngOnDestroy() {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  onRouteActivate(evt: any) {
    this.activeComponent.next(evt);
  }
}
