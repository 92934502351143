import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';
import { IAcRegistration } from '../shared/models/ac-registration.model';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { ToastService } from './toast.service';
import { IGenericContainerObject } from '../shared/models/genericContainerObject.model';


@Injectable({
  providedIn: 'root'
})
export class AcRegistrationsService {

  acRegistrations: IGenericContainerObject<IAcRegistration> = {};

  constructor(private httpClient: HttpClient, private toastService: ToastService) {
    this.getAcRegistrations().subscribe((result) => {
      if(result?.length) {
        for (const reg of result) {
          this.acRegistrations[reg.registration] = reg;
        }
      }
    })
  }

  getAcRegistrations(): Observable<IAcRegistration[]> {
    return this.httpClient.post(`${environment.api_base_url}ac-registrations/search`, { isActive: true }, {}).pipe(
      map((item: IResponse<IAcRegistration[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        this.toastService.showError("Error Loading Ac Registrations");
        return of([]);
      })
    );
  }

  saveAcRegistration(acRegistration: IAcRegistration): Observable<IAcRegistration> {
    let obs;
    if (acRegistration.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}ac-registrations/${acRegistration.id}`, acRegistration, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}ac-registrations/create`, acRegistration, {})
    }
    return obs.pipe(
      map((item: IResponse<IAcRegistration>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error saving AC Registration");
        return of({});
      })
    );
  }

  deleteAcRegistration(AcRegistrationId: number): Observable<any> {
    return this.httpClient.delete(`${environment.api_base_url}ac-registrations/${AcRegistrationId}`, {}).pipe(
      map((item: IResponse<boolean>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error deleting ac registration");
        return of({});
      })
    );
  }
}
