import { IAcType } from './ac-type.model';
import { IAcRegistration } from './ac-registration.model';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export interface IFilter {
  dateFrom?: NgbDateStruct;
  dateTo?: NgbDateStruct;
  completedOnly?: boolean;
  timespan: '' | '8Hours' | '12Hours' | '24Hours';
  acType: IAcType;
  acRegistration: IAcRegistration;
  station: string;
  flightNumber?: string;
  passengerGate?: string;
  aircraftStand?: string;
}

export class Filter implements IFilter {
  timespan: '8Hours' | '12Hours' | '24Hours';
  acType: IAcType;
  acRegistration: IAcRegistration;
  station: string;
  completedOnly?: boolean;
  flightNumber?: string;
  passengerGate?: string;
  aircraftStand?: string;
  dateFrom?: NgbDateStruct;
  dateTo?: NgbDateStruct;

  constructor(airport: string) {
    this.station = airport;
  }
}

export enum FilterCategory {
  ALL_FLIGHTS = 'ALL_FLIGHTS',
  COMPLETED_FLIGHTS = 'COMPLETED_FLIGHTS',
  DAY_OF_OPERATION = 'DAY_OF_OPERATION',
}
