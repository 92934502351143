import {Injectable} from '@angular/core';
import {TurnaroundStatus} from '../shared/constants/turnaround-status.constants';
import * as dayjs from 'dayjs';
import {Observable} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';
import {IExtendTurnaroundRequest} from '../shared/models/extendTurnaroundRequest.model';
import {IFlightsEditMemoRequest} from '../shared/models/flights-edit-memo-request.model';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable({
  providedIn: 'root'
})
export class FunctionService {

  constructor(private fns: AngularFireFunctions) {
  }

  changeTurnaroundState(newState: TurnaroundStatus, user: string, pairKey: string): Observable<any> {
    if (!newState || !user || !pairKey) {
      return of(false);
    }

    const params = {
      newState,
      user,
      pairKey,
      datetime: dayjs.utc().format()
    };
    const callable = this.fns.httpsCallable('rampChangeTurnaroundState');
    return callable(params);
  }

  requestTurnaroundExtension(request: IExtendTurnaroundRequest): Observable<any> {
    if (!request) {
      return of(false);
    }

    const callable = this.fns.httpsCallable('rampExtendTurnaroundRequest');
    return callable(request);
  }

  editMemo(request: IFlightsEditMemoRequest) {
    if (!request) {
      console.log('editMemo: arg request is empty');
      return of(false);
    }
    const callable = this.fns.httpsCallable('flightsEditMemo');
    return callable(request);
  }
}
