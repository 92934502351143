<div class="modal-header">
  <div class="modal-title">
    <div class="d-flex gap-2">
      <div class="d-flex flex-column">
        <div class="user-name">{{title}}</div>
        <div class="user-role roboto-font">{{user?.role | i18nSelect: userRoleObject.DISPLAY_TITLE_MAP }} ({{user.location}})</div>
      </div>
      <div *ngIf="showInfo" class="ms-2 mini-icon-container rounded-circle d-flex justify-content-center align-items-center align-self-center" [ngClass]="{'isActive': showInfoActive}" (click)="infoClicked()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.61 122.88" width="16" height="16">
          <path fill="#002060"
                d="M111.9,61.57a5.36,5.36,0,0,1,10.71,0A61.3,61.3,0,0,1,17.54,104.48v12.35a5.36,5.36,0,0,1-10.72,0V89.31A5.36,5.36,0,0,1,12.18,84H40a5.36,5.36,0,1,1,0,10.71H23a50.6,50.6,0,0,0,88.87-33.1ZM106.6,5.36a5.36,5.36,0,1,1,10.71,0V33.14A5.36,5.36,0,0,1,112,38.49H84.44a5.36,5.36,0,1,1,0-10.71H99A50.6,50.6,0,0,0,10.71,61.57,5.36,5.36,0,1,1,0,61.57,61.31,61.31,0,0,1,91.07,8,61.83,61.83,0,0,1,106.6,20.27V5.36Z"></path>
        </svg>
      </div>
    </div>
  </div>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeModal('Cross click')"></button>
</div>
<div class="modal-body p-0">
  <app-ramp-view-pair-chat [user]="user" [pair]="data" [showPairInfoControl]="showInfoActive" (closeModalAction)="closeModal($event)"></app-ramp-view-pair-chat>
</div>
