<div class="main-container">

  <div class="header-container header">
    <app-header [user]="userService.getUser() | async" [activeComponent]="activeComponent | async"></app-header>
  </div>
  <div class="outlet-container router">
    <router-outlet (activate)="onRouteActivate($event)"></router-outlet>
  </div>
  <div class="tabbar-container footer">
    <app-tabbar></app-tabbar>
  </div>

</div>
