import { Component, OnDestroy, OnInit } from '@angular/core';
import { IUser } from '../../shared/models/user.model';
import { ChatService } from '../../services/chat.service';
import { UserService } from '../../services/user.service';
import { IMyChatGroups } from '../../shared/models/my-chat-groups.interface';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IGenericContainerObject } from '../../shared/models/genericContainerObject.model';
import { RoutableModel } from '../../shared/models/routable.model';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, OnDestroy, RoutableModel {
  backUrl: string = '/home';
  canGoBack: boolean = true;
  pageTitle: string = 'Chat';

  groupSelection: IMyChatGroups;
  searchKeyword: string;
  formGroup: FormGroup;
  users: IGenericContainerObject<IUser> = {};
  popoverUsers: IUser[] = [];
  destroy$ = new Subject();

  constructor(private chatService: ChatService, public userService: UserService) { }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      searchText: new FormControl(null)
    });
    this.userService.availableChatUsers.pipe(takeUntil(this.destroy$)).subscribe((users) => {
      if (this.formGroup.value.searchText?.location) {
        this.popoverUsers = users.filter((user) => user?.location?.toLowerCase() === this.formGroup.value.searchText?.toLowerCase() || user.firstname.concat(user.lastname).toLowerCase().includes(this.formGroup.value.searchText?.replace(" ", "").toLowerCase()));
      } else {
        this.popoverUsers = users;
      }
    });
    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(250)).subscribe((change) => {
      this.popoverUsers = Object.values(this.userService.availableChatUsers.value).filter((user) => user?.location?.toLowerCase() === change.searchText?.toLowerCase() || user.firstname.concat(user.lastname).toLowerCase().includes(change.searchText?.replace(" ", "").toLowerCase()));
    });
  }

  ngOnDestroy() {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  onConversationSearch(event: string) {
    this.searchKeyword = event;
  }

  createChatGroup(user: IUser) {
    const existingGroup = Object.values(this.chatService.messageGroups).find((group) => group.userId === user.id);
    if (existingGroup) {
      //this.activeIndex = existingGroup.chatGroupId;
      this.groupSelection = existingGroup;
      return;
    }

    this.chatService.createChatGroup([this.userService.userSubject.value?.id, user.id]).subscribe((chatGroupId) => {
      if (chatGroupId) {
        this.chatService.getMyChatGroups().subscribe((result) => {
          this.chatService.refreshChatGroups(result);
          this.groupSelection = Object.values(this.chatService.messageGroups).find((group) => group.chatGroupId === chatGroupId);
          //this.activeIndex = chatGroupId;
        });
      }
    })
  }
}
