import { Component, OnInit } from '@angular/core';
import { RoutableModel } from '../../shared/models/routable.model';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, RoutableModel {
  backUrl: string = '/home';
  canGoBack: boolean = false;
  pageTitle = 'Home';
  constructor(public userService: UserService) { }

  ngOnInit(): void {
  }

}
