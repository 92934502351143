<div class="main-container p-3 d-flex flex-column dark-blue gap-3 mt-3" >
  <ng-container *ngIf="reauthForm && !changed; else change" [formGroup]="reauthForm">
    <div>
      <div>
        E-mail
      </div>
      <input type="text" class="form-control" formControlName="email">
    </div>
    <div>
      <div>
        Password
      </div>
      <app-password-input formControlName="password"></app-password-input>
    </div>

    <button class="btn button-blue" [disabled]="isBusy || reauthForm.invalid" (click)="reauthAndChangePassword()">

      <ng-container *ngIf="!isBusy; else loading">
        Change Password
      </ng-container>
      <ng-template #loading>
        <app-loading-spinner></app-loading-spinner>
      </ng-template>
    </button>

    <div class="error">
      {{ error }}
    </div>
  </ng-container>
  <ng-template #change>
    <ng-container *ngIf="!changed" [formGroup]="form">
      <div>
        <div>
          New password
        </div>
        <app-password-input formControlName="newPassword"></app-password-input>
      </div>

      <div>
        <div>
          Repeat new password
        </div>
        <app-password-input formControlName="newPassword2"></app-password-input>
      </div>

      <button class="btn button-blue" [disabled]="isBusy || form.invalid" (click)="changePassword()">

        <ng-container *ngIf="!isBusy; else loading">
          Change Password
        </ng-container>
        <ng-template #loading>
          <app-loading-spinner></app-loading-spinner>
        </ng-template>
      </button>

      <div class="error">
        {{ error }}
      </div>
    </ng-container>

    <ng-container *ngIf="changed">
      <div class="d-flex flex-column gap-2">
        <div class="success text-start">
          Your password has been changed!
        </div>
      </div>
      <button class="btn button-blue" routerLink="">Back to main menu</button>
    </ng-container>
  </ng-template>


</div>
