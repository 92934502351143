import { Component, OnInit } from '@angular/core';
import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'app-tabbar',
  templateUrl: './tabbar.component.html',
  styleUrls: ['./tabbar.component.scss']
})
export class TabbarComponent implements OnInit {

  indicatorText: string = '';
  constructor(private chatService: ChatService) { }

  ngOnInit(): void {
    this.chatService.unreadCount.subscribe((result) => {
      if (!result) {
        this.indicatorText = '';
        return;
      }
      this.indicatorText = result.toString();
    })
  }

}
