<div class="menu-container d-flex flex-column gap-1">
  <div class="welcome">Welcome</div>
  <div class="user-name poppins">{{ userService.userSubject.value?.firstname }} {{ userService.userSubject.value?.lastname }}</div>
  <div class="menu mt-3">
    <app-home-menu-item text="All<br>Flights" routerLink="/all-flights" icon="icon-all-flights" [iconSize]="40"></app-home-menu-item>
    <app-home-menu-item text="Day of<br>Operation" class="justify-content-end" routerLink="/turnarounds" icon="icon-day-of-operation-colored" [iconSize]="40"></app-home-menu-item>
    <app-home-menu-item text="Completed<br>Flights" routerLink="/completed" icon="icon-completed-flights-colored" [iconSize]="40"></app-home-menu-item>
    <app-home-menu-item text="Contact<br>Data" class="justify-content-end" routerLink="/contacts" icon="icon-contact-data-colored" [iconSize]="40"></app-home-menu-item>
    <app-home-menu-item text="Reports &<br>Data" routerLink="/reports" icon="icon-reports-and-data" [iconSize]="40"></app-home-menu-item>
    <app-home-menu-item text="Chat &<br>Messages" class="justify-content-end" routerLink="/chat" icon="icon-chats-and-messages-colored" [iconSize]="40"></app-home-menu-item>
  </div>
</div>
