import { IPairDetail } from '../models/pair-detail.model';
import { IPairOverview, isPairOverview } from '../models/pair-overview.model';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';

export function extractSeatingConfigurations(text: string): string[] {
  const seatingConfigurationRegex = /([A-Z][0-9]{1,3})/g;
  return text.split(seatingConfigurationRegex).filter((el) => el !== '');
}

export function getLatestArrivalTime(pair: IPairDetail | IPairOverview, arrivalLeg = true): Date {
  if (isPairOverview(pair)) {
    return pair.ata || pair.eta || pair.sta;
  }
  if (arrivalLeg) {
    return pair.arrivalLeg?.ata || pair.arrivalLeg?.eta || pair.arrivalLeg?.sta;
  }
  return pair.departureLeg?.ata || pair.departureLeg?.eta || pair.departureLeg?.sta;
}

export function getLatestDepartureTime(pair: IPairDetail | IPairOverview, arrivalLeg = true): Date {
  if (isPairOverview(pair)) {
    return pair.atd || pair.etd || pair.std;
  }
  if (arrivalLeg) {
    return pair.arrivalLeg?.atd || pair.arrivalLeg?.etd || pair.arrivalLeg?.std;
  }
  return pair.departureLeg?.atd || pair.departureLeg?.etd || pair.departureLeg?.std;
}

export function ngbDateToDayjs(date: NgbDateStruct): dayjs.Dayjs {
  return dayjs.utc([date.year, date.month - 1, date.day]);
}
