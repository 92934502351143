import * as firebase from '@angular/fire/firestore';
import * as dayjs from 'dayjs';

export function convertTimestamps(obj: Record<string, any>): void {
  Object.keys(obj).forEach(key => {
    if (obj[key] instanceof firebase.Timestamp) {
      obj[key] = dayjs.utc(obj[key]?.seconds, 'X').toDate();
    } else if (typeof obj[key] === 'object' && obj[key] !== null && obj) {
      convertTimestamps(obj[key]);
    }
  });
}
