<div class="d-flex flex-column p-3 mt-2 gap-3 dark-blue">
  <div class="text-start fw-bold">Select period</div>
  <div class="d-flex gap-4">
    <div>
      <label>Date From</label>
        <input class="form-control"
               name="dp" [(ngModel)]="dateFrom" ngbDatepicker #d="ngbDatepicker" (focusin)="d.toggle()" [autoClose]="true" (dateSelect)="d.close()" (ngModelChange)="this.filterChanged.next(null)">
    </div>

    <div>
      <label>Date To</label>
        <input class="form-control"
               name="dp" [(ngModel)]="dateTo" ngbDatepicker #d2="ngbDatepicker" (focusin)="d2.toggle()" [autoClose]="true" (dateSelect)="d2.close()" (ngModelChange)="this.filterChanged.next(null)">
    </div>
  </div>
  <div class="flex flex-column">
    <app-tabs-navigation [tabs]="['Performance', 'Memos']" (tabChanged)="activeTab = $event"></app-tabs-navigation>
    <ng-container *ngIf="activeTab === 'Performance'">
      <div class="stats-grid">
        <div># of Turnarounds:</div>
        <div class="fw-bold">{{ turnaroundCount }}</div>

        <div>Turnarounds on Time:</div>
        <div class="fw-bold">{{ turnaroundsOnTime }} <ng-container *ngIf="turnaroundCount > 0">{{ (turnaroundsOnTime / turnaroundCount * 100).toFixed(0) }}%)</ng-container></div>

        <div>Turnarounds overdue > 5 mins:</div>
        <div class="fw-bold">{{ turnaroundsOverdueOver5 }} <ng-container *ngIf="turnaroundCount > 0">({{ (turnaroundsOverdueOver5 / turnaroundCount * 100).toFixed(0) }}%)</ng-container></div>

        <div>Turnarounds < 5 mins</div>
        <div class="fw-bold">{{ turnaroundsUnderdue5 }} <ng-container *ngIf="turnaroundCount > 0">({{ (turnaroundsUnderdue5 / turnaroundCount * 100).toFixed(0) }}%)</ng-container></div>
      </div>
    </ng-container>
    <ng-container *ngIf="activeTab === 'Memos'">
      <div class="d-flex flex-column gap-4 overflow-auto dark-blue memo-list" (scroll)="onScroll($event)">
        <div class="d-flex flex-column gap-2" *ngFor="let memo of filteredMemos | slice:0:limit">
          <ng-container *ngIf="legs[memo.legId]">
            <div class="leg-info">
              {{ legs[memo.legId].airlineDesignator + legs[memo.legId].flightNumber }} | {{ legs[memo.legId].departureStation }} - {{ legs[memo.legId].arrivalStation }} | {{dayjs.utc(legs[memo.legId].std).format('DD/MM/YYYY') }}
            </div>
            <div class="light-blue-border p-3">
              {{ memo.content }}
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>


</div>
