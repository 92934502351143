import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './layouts/login/login.component';
import { TurnaroundsPageComponent } from './content/turnarounds/turnarounds-page.component';
import { MessagesComponent } from './content/messages/messages.component';
import { AuthGuard } from './auth.guard';
import { HomeComponent } from './content/home/home.component';
import { MainComponent } from './content/main/main.component';
import { TurnaroundsPairDetailComponent } from './content/turnarounds/turnarounds-pair-detail/turnarounds-pair-detail.component';
import { MessageGroupDetailComponent } from './content/messages/message-group-detail/message-group-detail.component';
import { UnderConstructionComponent } from './content/under-construction/under-construction.component';
import { AllFlightsComponent } from './content/all-flights/all-flights.component';
import { CompletedFlightsComponent } from './content/completed-flights/completed-flights.component';
import { ReportsAndDataComponent } from './content/reports-and-data/reports-and-data.component';
import { ContactDataComponent } from './content/contact-data/contact-data.component';
import { ContactComponent } from './content/contact/contact.component';
import { FaqPageComponent } from './content/faq-page/faq-page.component';
import { ChangePasswordComponent } from './content/change-password/change-password.component';
import { ForgotPasswordComponent } from './content/forgot-password/forgot-password.component';

const routes: Routes = [{
  path: 'login',
  component: LoginComponent,
},
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [{
      path: '',
      pathMatch: 'full',
      component: HomeComponent
    },
      {
        path: 'turnarounds/:id',
        component: TurnaroundsPairDetailComponent,
      },
      {
        path: 'turnarounds',
        component: TurnaroundsPageComponent,
      },
      {
        path: 'chat/:id',
        component: MessageGroupDetailComponent,
      },
      {
        path: 'chat',
        component: MessagesComponent,
      },
      {
        path: 'under-construction',
        component: UnderConstructionComponent,
      },
      {
        path: 'completed/:id',
        component: TurnaroundsPairDetailComponent,
      },
      {
        path: 'completed',
        component: CompletedFlightsComponent,
      },
      {
        path: 'flight/:id',
        component: TurnaroundsPairDetailComponent,
      },
      {
        path: 'all-flights',
        component: AllFlightsComponent,
      },
      {
        path: 'reports',
        component: ReportsAndDataComponent,
      },
      {
        path: 'contacts',
        component: ContactDataComponent,
      },
      {
        path: 'contact/:id',
        component: ContactComponent
      },
      {
        path: 'faq',
        component: FaqPageComponent,
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: '',
  }
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
