<div class="modal-header">
  <div class="modal-title">Create and Manage Memos</div>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeModal('Cross click')"></button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="d-flex flex-column">
      <nav class="navbar navbar-dark flex-down">
        <ul class="list-horizontal text-white float-start roboto-font">
          <li [ngClass]="{ active: activeId === 1 }" (click)="setActiveId(1)">Arriving Leg</li>
          <li [ngClass]="{ active: activeId === 2 }" (click)="setActiveId(2)">Departing Leg</li>
        </ul>
      </nav>
      <div class="roboto-font dark-blue fw-800">
        <ng-container *ngIf="activeId === 1; else departure">
          Selected leg: {{this.pair.arrivalLeg.legKey ? (this.pair.arrivalLeg.legKey | legKeyInterpreter) : ' - ' }}
        </ng-container>
        <ng-template #departure>
          Selected leg: {{this.pair.departureLeg.legKey ? (this.pair.departureLeg.legKey | legKeyInterpreter) : ' - '}}
        </ng-template>
      </div>
      <div class="c-tab-content">
        <div *ngIf="activeId === 1">
          <div class="d-flex mb-2 mt-1">
            <div *ngIf="arrivalMemo" class="col-12 memo-update">
              <!-- last update 20.05.2019 22:30 by Anna Black -->
              {{arrivalMemo?.content !== '' ? 'Last update on ' : 'Deleted on '}} {{arrivalMemo?.lastChangedAt | date}} by {{arrivalMemoUser?.username}}
            </div>
            <div *ngIf="!arrivalMemo" class="col-12 memo-update">
              No memo added yet
            </div>
          </div>
          <div class="row">
            <div class="form-group w-100">
              <textarea class="form-control dark-blue roboto-font" id="arrivalMemoContent" formControlName="arrivalText" rows="7" ngbAutofocus></textarea>
            </div>
          </div>
        </div>
        <div *ngIf="activeId === 2">
          <div class="row mb-2 mt-1">
            <div *ngIf="departureMemo" class="col-12 memo-update">
              {{departureMemo?.content !== '' ? 'Last update on ' : 'Deleted on '}} {{departureMemo?.lastChangedAt | date}} by {{departureMemoUser?.username}}
            </div>
            <div *ngIf="!departureMemo" class="col-12 memo-update">
              No memo added yet
            </div>
          </div>
          <div class="row">
            <div class="form-group w-100">
              <textarea class="form-control dark-blue roboto-font" id="departureMemoContent" formControlName="departureText" rows="7" ngbAutofocus></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between">
          <button class="btn btn-outline-danger flex-basis-25"
                  (click)="deleteClicked()"
                  [disabled]="activeId === 1 ? !arrivalMemo?.content || arrivalMemo?.content === '' : !departureMemo?.content || departureMemo?.content === ''"
          >Delete</button>
        <button class="btn flex-basis-25 cancel-btn" (click)="closeModal('')">Cancel</button>
          <button class="btn button-blue flex-basis-25"
                  (click)="saveClicked()"
                  [disabled]="activeId === 1 ? form.get('arrivalText').invalid : form.get('departureText').invalid"
          >Save</button>
      </div>
      </div>
  </form>
</div>
