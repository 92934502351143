<div class="conversation-container" #conversationContainer>


  <ng-container *ngIf="conversation?.length; else noMessages">
    <div class="row gx-0" *ngFor="let convo of conversation; let i = index">
      <div class="text-center datetime roboto-font dark-blue" *ngIf="i === conversation.length-1 || dateDiff(conversation[i+1].createdAt, convo.createdAt) > 30">{{ convo.createdAt | date: 'MMM d, y, hh:mm':(timezone$ | async)}}</div>
      <app-message-item [container]="conversationContainer" [ownMessage]="+convo.createdBy === userService.userSubject?.value?.id" [message]="convo"  [user]="user"></app-message-item>
    </div>
  </ng-container>

  <ng-template #noMessages>
    <ng-container *ngIf="isLoading">
      <div class="d-flex justify-content-center h-100 gap-3">
        <div class="text-center align-self-center roboto-font dark-blue">Loading messages...</div>
        <div class="spinner-border text-primary align-self-center" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

    </ng-container>
    <ng-container *ngIf="!isLoading">
      <div class="d-flex flex-column justify-content-center h-100">
        <div class="text-center fs-3 roboto-font dark-blue">No messages yet.</div>
      </div>
    </ng-container>
  </ng-template>

</div>
