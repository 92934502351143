<!-- <input type="text" id="searchbar" class="form-control" placeholder="Suchen"/> -->

<div class="input-group">
  <div class="d-flex">
    <div class="input-group-text">
      <fa-icon [icon]="searchIcon" [styles]="{ color: '#bfbfbf' }"></fa-icon>
    </div>
  </div>
  <input type="text" class="form-control" id="searchbar" placeholder="Search" #searchBar (input)="onSearch.emit(searchBar.value)">
</div>
