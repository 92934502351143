import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TurnaroundsPairListComponent} from './turnarounds-pair-list/turnarounds-pair-list.component';
import {TurnaroundsPairListItemComponent} from './turnarounds-pair-list/turnarounds-pair-list-item/turnarounds-pair-list-item.component';
import {TurnaroundsPageComponent} from './turnarounds-page.component';
import {TurnaroundsSubMenuComponent} from './turnarounds-sub-menu/turnarounds-sub-menu.component';
import {TurnaroundsPairDetailComponent} from './turnarounds-pair-detail/turnarounds-pair-detail.component';
import {StartTurnaroundComponent} from './turnarounds-pair-detail/start-turnaround/start-turnaround.component';
import {TurnaroundTimeComponent} from './turnarounds-pair-detail/turnaround-time/turnaround-time.component';
import {RemainingTimeComponent} from './turnarounds-pair-detail/remaining-time/remaining-time.component';
import {ExtendTimeComponent} from './turnarounds-pair-detail/extend-time/extend-time.component';
import {TurnaroundControlsComponent} from './turnarounds-pair-detail/turnaround-controls/turnaround-controls.component';
import {RoundProgressModule} from 'angular-svg-round-progressbar';
import {SharedModule} from '../../shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    TurnaroundsPairListComponent,
    TurnaroundsPairListItemComponent,
    TurnaroundsPageComponent,
    TurnaroundsSubMenuComponent,
    TurnaroundsPairDetailComponent,
    StartTurnaroundComponent,
    TurnaroundTimeComponent,
    RemainingTimeComponent,
    ExtendTimeComponent,
    TurnaroundControlsComponent
  ],
  imports: [
    CommonModule,
    RoundProgressModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    FontAwesomeModule,
    RouterModule,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  exports: [TurnaroundsPairListComponent,
    TurnaroundsPairListItemComponent,
    TurnaroundsPageComponent,
    TurnaroundsSubMenuComponent,
    TurnaroundsPairDetailComponent,
    StartTurnaroundComponent,
    TurnaroundTimeComponent,
    RemainingTimeComponent,
    ExtendTimeComponent,
    TurnaroundControlsComponent],
})
export class TurnaroundsModule {
}

