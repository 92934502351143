import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'legKeyInterpreter'
})
export class LegKeyInterpreterPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if (!value || value === '' || !value.includes('-')) {
      return ' - ';
    }

    const splitValueList = value.split('-');

    if (splitValueList.length < 5) {
      return ' - ';
    }

    return `${splitValueList[4]}   ${splitValueList[0]} - ${splitValueList[1]}`;
  }
}
