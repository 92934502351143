import {Component, Input, OnInit} from '@angular/core';
import {TurnaroundStatus} from '../../constants/turnaround-status.constants';
import { IPairDetail } from '../../models/pair-detail.model';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-pair-list-item',
  templateUrl: './pair-list-item.component.html',
  styleUrls: ['./pair-list-item.component.scss']
})
export class PairListItemComponent implements OnInit {

  @Input() pair: IPairDetail;

  taStatus = TurnaroundStatus;
  turnaroundTime?: number;

  timezone = '+00:00';
  constructor() { }

  ngOnInit(): void {
    this.turnaroundTime = (this.pair.departureLeg?.tod?.getTime() - this.pair.arrivalLeg?.toa?.getTime()) / 1000 / 60;
  }

  calcDateDifferenceInDays(date1: Date, date2: Date, tz: string): number {
    if (!date1 || !date2) {
      return 0;
    }

    let d1 = dayjs(date1);
    let d2 = dayjs(date2);
    if (d1 && d2 && d1.isValid() && d2.isValid()) {
      if (tz && tz.length === 5) {
        const hours = Number(tz.slice(1, 3));
        const minutes = Number(tz.slice(3, 5)) + hours * 60;
        d1 = d1.utcOffset(minutes);
        d2 = d2.utcOffset(minutes);
      }
      d1 = d1.startOf('day');
      d2 = d2.startOf('day');
      return Math.abs(d2.diff(d1, 'd'));
    }
    return 0;
  }

  calcDateDifferenceString(date1: Date, date2: Date, tz: string): string {
    const res = this.calcDateDifferenceInDays(date1, date2, tz);
    if (res === 0) {
      return '';
    }
    const sign = dayjs.utc(date1).isAfter(date2) ? '-' : '+';
    return sign + res;
  }

  get isArrivalLegSta(): boolean {
    return this.pair && this.pair.arrivalLeg && this.pair.arrivalLeg.sta !== undefined;
  }

  get isArrivalLegEta(): boolean {
    return this.pair && this.pair.arrivalLeg && this.pair.arrivalLeg.eta !== undefined;
  }

  get isArrivalLegAta(): boolean {
    return this.pair && this.pair.arrivalLeg && this.pair.arrivalLeg.ata !== undefined;
  }

  get hasArrivalAndDepartureLeg(): boolean {
    return this.pair && this.pair.arrivalLeg && this.pair.departureLeg !== undefined;
  }

}
