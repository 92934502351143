import { Component, OnInit } from '@angular/core';
import { RoutableModel } from '../../shared/models/routable.model';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TurnaroundService } from '../../services/turnaround.service';
import { debounceTime, firstValueFrom, Observable, skipWhile, Subject } from 'rxjs';
import { IPairTurnaroundStatesModel } from '../../shared/models/pair-turnaround-states.model';
import { Searchable } from '../../shared/utils/searchable.type';
import { ngbDateToDayjs } from '../../shared/utils/utils';
import { UserService } from '../../services/user.service';
import { TurnaroundStatus } from '../../shared/constants/turnaround-status.constants';
import { IGenericContainerObject } from '../../shared/models/genericContainerObject.model';
import { IPairsModel } from '../../shared/models/pairs.model';
import { PairService } from '../../services/pair.service';
import { IMemoModel } from '../../shared/models/memo.model';
import { MemosService } from '../../services/memos.service';
import { LegService } from '../../services/leg.service';
import { ILegsModel } from '../../shared/models/legs.model';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-reports-and-data',
  templateUrl: './reports-and-data.component.html',
  styleUrls: ['./reports-and-data.component.scss']
})
export class ReportsAndDataComponent implements OnInit, RoutableModel {
  backUrl: string = '';
  canGoBack: boolean = true;
  pageTitle: string = 'Reports & Data';
  dateFrom: NgbDateStruct;
  dateTo: NgbDateStruct;
  activeTab: string = 'Performance';
  isLoading = true;
  turnaroundData: IPairTurnaroundStatesModel[];
  filterChanged = new Subject();
  pairData: IGenericContainerObject<IPairsModel> = {};
  memos: IMemoModel[];
  filteredMemos: IMemoModel[];
  limit = 25;
  legs: IGenericContainerObject<ILegsModel> = {};
  dayjs = dayjs;

  // Stats
  turnaroundCount = 0;
  turnaroundsOnTime = 0;
  turnaroundsOverdueOver5 = 0;
  turnaroundsUnderdue5 = 0;

  constructor(private turnaroundStatesService: TurnaroundService, private userService: UserService, private pairService: PairService, private memoService: MemosService, private legService: LegService) { }

  ngOnInit(): void {
    this.filterChanged.pipe(debounceTime(500)).subscribe(() => {
      this.onDateChange();
    })
    this.downloadTurnaroundData().subscribe((result) => {
      this.turnaroundData = result;
      this.calculateTurnaroundPerformance();
      this.isLoading = false;
    });
    this.fetchMemos();
  }

  fetchMemos() {
    if (!this.userService.userSubject.value) {
      this.userService.userSubject.pipe(skipWhile((val) => !val)).subscribe(() => this.fetchMemos());
      return;
    }
    const filters: Searchable<IMemoModel> = { isActive: true, lastChangedBy: this.userService.userSubject.value.id };
    // if (this.dateFrom && ngbDateToDayjs(this.dateFrom).isValid()) {
    //   filters.lastChangedAt = {
    //     from: ngbDateToDayjs(this.dateFrom).toDate()
    //   };
    // }
    // if (this.dateTo && ngbDateToDayjs(this.dateTo).isValid()) {
    //   if (!filters.lastChangedAt) {
    //     filters.lastChangedAt = {};
    //   }
    //   (filters.lastChangedAt as Searchable<any>).to = ngbDateToDayjs(this.dateTo).toDate();
    // }
    this.memoService.getMemos(filters).subscribe((result) => {
      this.memos = result;
      this.getLegs();
    });
  }

  getLegs() {
    this.legs = {};
    const filters: Searchable<ILegsModel> = { isActive: true, id: this.memos.map((memo) => memo.legId) };
    if (this.dateFrom && ngbDateToDayjs(this.dateFrom).isValid()) {
      filters.lastChangedAt = {
        from: ngbDateToDayjs(this.dateFrom).toDate()
      };
    }
    if (this.dateTo && ngbDateToDayjs(this.dateTo).isValid()) {
      if (!filters.lastChangedAt) {
        filters.lastChangedAt = {};
      }
      (filters.lastChangedAt as Searchable<any>).to = ngbDateToDayjs(this.dateTo).toDate();
    }
    this.legService.getLegs(filters).subscribe((result) => {
      for (const leg of result) {
        this.legs[leg.id] = leg;
      }
      this.filteredMemos = this.memos.filter((memo) => this.legs[memo.legId]);
    });
  }

  async calculateTurnaroundPerformance() {
    if (!this.userService.userSubject.value) {
      this.userService.userSubject.pipe(skipWhile((val) => !val)).subscribe(() => this.calculateTurnaroundPerformance());
      return;
    }
    this.turnaroundCount = 0;
    this.turnaroundsOnTime = 0;
    this.turnaroundsOverdueOver5 = 0;
    this.turnaroundsUnderdue5 = 0;
    const turnaroundPairIds = new Set<number>();
    const pairsToFetch = new Set<number>();
    const finishedTurnarounds = this.turnaroundData.filter((turnaround) => {
      if (turnaround.isActive && turnaround.turnaroundStateId === TurnaroundStatus.IDS.FINISHED && turnaround.lastChangedBy === this.userService.userSubject.value.id) {
        turnaroundPairIds.add(turnaround.pairId);
        if (!this.pairData[turnaround.pairId]) {
          pairsToFetch.add(turnaround.pairId);
        }
        return true;
      }
      return false;
    });
    this.turnaroundCount = finishedTurnarounds.length;
    if (pairsToFetch.size) {
      const pairs = await firstValueFrom(this.pairService.getPairs({ id: Array.from(pairsToFetch) }));
      for (const pair of pairs) {
        this.pairData[pair.id] = pair;
      }
    }
    for (const id of turnaroundPairIds) {
      const originalTurnaround =  this.pairData[id]?.groundTimeInMinutes;
      const actualTurnaround = this.calculateMinutes(this.turnaroundData.filter((turnaround) => turnaround.pairId === id));
      console.log('pair id:', id);
      console.log('Original turnaround time:', originalTurnaround);
      console.log('Actual turnaround time:', actualTurnaround);
      if (actualTurnaround < originalTurnaround + 5 && actualTurnaround >= originalTurnaround -5) {
        this.turnaroundsOnTime++;
      } else if (actualTurnaround < originalTurnaround - 5) {
        this.turnaroundsUnderdue5++;
      } else {
        this.turnaroundsOverdueOver5++;
      }
    }
  }

  onDateChange() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.downloadTurnaroundData().subscribe((result) => {
      this.turnaroundData = result;
      this.calculateTurnaroundPerformance();
      this.isLoading = false;
    });

    //this.fetchMemos();
    this.getLegs();
  }

  downloadTurnaroundData(): Observable<any> {
    // if (!this.userService.userSubject.value) {
    //   return this.userService.userSubject.pipe(skipWhile((val) => !val), mergeMap(() => this.downloadTurnaroundData()));
    // }
    const filters: Searchable<IPairTurnaroundStatesModel> = { isActive: [true, false] };
    if (this.dateFrom && ngbDateToDayjs(this.dateFrom).isValid()) {
      filters.lastChangedAt = {
        from: ngbDateToDayjs(this.dateFrom).toDate()
      };
    }
    if (this.dateTo && ngbDateToDayjs(this.dateTo).isValid()) {
      if (!filters.lastChangedAt) {
        filters.lastChangedAt = {};
      }
      (filters.lastChangedAt as Searchable<any>).to = ngbDateToDayjs(this.dateTo).toDate();
    }
    return this.turnaroundStatesService.getTurnarounds(filters);
  }

  calculateMinutes(data: IPairTurnaroundStatesModel[]): number {
    let totalMinutes = 0;

    // Reverse the array as we are always looking for previous values
    const reversedData = data?.length >= 2 && data[0].id < data[1].id ? data.reverse() : data;

    for (let i = 0; i < reversedData.length; i++) {
      let currentEntry = reversedData[i];

      if (currentEntry.turnaroundStateId === 6) {
        for (let j = i+1; j < reversedData.length; j++) {
          if (reversedData[j].turnaroundStateId === 5 || reversedData[j].turnaroundStateId === 3) {
            totalMinutes += this.calculateDifference(currentEntry.lastChangedAt as unknown as string, reversedData[j].lastChangedAt as unknown as string);
            break;
          }
        }
      }

      if (currentEntry.turnaroundStateId === 4) {
        for (let j = i+1; j < reversedData.length; j++) {
          if (reversedData[j].turnaroundStateId === 3) {
            totalMinutes += this.calculateDifference(currentEntry.lastChangedAt as unknown as string, reversedData[j].lastChangedAt as unknown as string);
            break;
          }
        }
      }
    }

    return totalMinutes;
  }

// Helper function to calculate difference in minutes between two dates
  calculateDifference(datetime1: string, datetime2: string): number {
    const date1 = new Date(datetime1);
    const date2 = new Date(datetime2);

    return Math.abs((date1.getTime() - date2.getTime()) / (1000 * 60));
  }

  onScroll(event: any) {
    // visible height + pixel scrolled >= total height
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if (this.limit < this.memos.length) {
        this.limit += 25;
      }
    }
  }
}
