import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { firstValueFrom, Subject } from 'rxjs';
import { IPairDetail } from '../../models/pair-detail.model';
import { IUser } from '../../models/user.model';
import { IChatMessageModel } from '../../models/chat-message.model';
import { ChatService } from '../../../services/chat.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-ramp-view-pair-chat',
  templateUrl: './ramp-view-pair-chat.component.html',
  styleUrls: ['./ramp-view-pair-chat.component.scss']
})
export class RampViewPairChatComponent implements OnInit, OnDestroy, AfterViewChecked {

  @Input() pair: IPairDetail;
  @Input() showPairInfoControl: boolean;
  @Input() isNotModal: boolean;

  @Input() user: IUser;
  @Output() closeModalAction = new EventEmitter();
  @Input() chatGroupId: number;

  unsubscribe$ = new Subject();

  conversation: IChatMessageModel[] = [];

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  constructor(private userService: UserService, private chatService: ChatService) {
  }

  async findGroup(memberIds: number[]): Promise<void> {
    this.chatGroupId = await firstValueFrom(this.chatService.getChatGroup(memberIds));
  }

  ngOnInit(): void {
    if (!this.chatGroupId) {
      this.findGroup([this.userService.userSubject?.value?.id, this.user?.id]);
    }
  }

  ngAfterViewChecked() {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }
}
