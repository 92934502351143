import { Component, OnInit } from '@angular/core';
import { RoutableModel } from '../../shared/models/routable.model';
import { IUser } from '../../shared/models/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, skipWhile } from 'rxjs';
import { UserService } from '../../services/user.service';
import { UserRoles } from '../../shared/constants/user-roles.constants';
import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, RoutableModel {
  backUrl: string = '/contacts';
  canGoBack: boolean = true;
  pageTitle: string;
  user: IUser;
  ownUser = false;

  constructor(private route: ActivatedRoute, private userService: UserService, private router: Router, private chatService: ChatService) {
    const userId: number = (this.route.params as BehaviorSubject<any>).value?.id;
    if (!userId) {
      this.router.navigateByUrl('/contacts');
      return;
    }

    if (window.history.state.user) {
      this.user = window.history.state.user;
      this.checkUser();
      this.pageTitle = `${this.user.firstname} ${this.user.lastname}`;
      return;
    }
    this.userService.getUserById(userId).subscribe((user) => {
      this.user = user;
      this.checkUser();
      this.pageTitle = `${this.user.firstname} ${this.user.lastname}`;
    });
  }

  ngOnInit(): void {
  }

  checkUser() {
    if (!this.user) {
      return;
    }
    if (!this.userService.userSubject.value) {
      this.userService.userSubject.pipe(skipWhile((val) => !val)).subscribe(() => this.checkUser());
      return;
    }
    if (this.user.id === this.userService.userSubject.value.id) {
      this.ownUser = true;
    }
  }

  protected readonly UserRoles = UserRoles;

  openChat() {
    this.chatService.createChatGroup([this.user.id, this.userService.userSubject.value.id]).subscribe((result) => {
     this.chatService.getMyChatGroups().subscribe((groups) => {
       this.chatService.refreshChatGroups(groups);
       this.router.navigateByUrl('/chat/' + result);
     });
    });
  }
}
