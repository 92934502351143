import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IPairDetail } from '../../../shared/models/pair-detail.model';
import { IMyChatGroups } from '../../../shared/models/my-chat-groups.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatService } from '../../../services/chat.service';
import { RoutableModel } from '../../../shared/models/routable.model';
import { BehaviorSubject, filter, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-message-group-detail',
  templateUrl: './message-group-detail.component.html',
  styleUrls: ['./message-group-detail.component.scss']
})
export class MessageGroupDetailComponent implements OnInit, RoutableModel, OnDestroy {
  backUrl: string = '/chat';
  canGoBack: boolean = true;
  pageTitle: string;
  kill$ = new Subject();


  @Input() messageGroup: IMyChatGroups;
  @Output() exitDetailView = new EventEmitter();
  data: IPairDetail;
  chatGroupId: number;

  showInfoActive: boolean;


  constructor(private router: Router, private chatService: ChatService, private route: ActivatedRoute) {
    this.chatGroupId = Number((this.route.params as BehaviorSubject<any>).value.id);
    if (!this.chatGroupId || this.chatGroupId < 0) {
      this.router.navigateByUrl('/chat');
      return;
    }
    if (!this.chatService.groupsRead.value) {
      this.chatService.groupsRead.pipe(takeUntil(this.kill$.pipe(filter(val => val === true)))).subscribe((val) => {
        if(val) {
          this.messageGroup = this.chatService.messageGroups[this.chatGroupId];
          if (!this.messageGroup) {
            this.router.navigateByUrl('/chat');
            return;
          }
          this.pageTitle = [this.messageGroup.__user__.firstname, this.messageGroup.__user__.lastname].join(' ');
        }
      });
      return;
    }
    this.messageGroup = this.chatService.messageGroups[this.chatGroupId];
    if (!this.messageGroup) {
      this.router.navigateByUrl('/chat');
      return;
    }
    this.pageTitle = [this.messageGroup.__user__.firstname, this.messageGroup.__user__.lastname].join(' ');
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.kill$.next(true);
    this.kill$.complete();
  }

  infoClicked() {
    this.showInfoActive = !this.showInfoActive;
  }

}
