import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TurnaroundsModule } from './content/turnarounds/turnarounds.module';
import { HeaderComponent } from './layouts/header/header.component';
import { LoginComponent } from './layouts/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabbarComponent } from './layouts/tabbar/tabbar.component';
import { MessagesModule } from './content/messages/messages.module';
import { TabbarItemComponent } from './layouts/tabbar/tabbar-item/tabbar-item.component';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideDatabase,getDatabase } from '@angular/fire/database';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthInterceptor } from './auth.interceptor';
import { SharedModule } from './shared/shared.module';
import { HomeComponent } from './content/home/home.component';
import { MainComponent } from './content/main/main.component';
import { HomeMenuItemComponent } from './content/home/home-menu-item/home-menu-item.component';
import { UnderConstructionComponent } from './content/under-construction/under-construction.component';
import { AllFlightsComponent } from './content/all-flights/all-flights.component';
import { CompletedFlightsComponent } from './content/completed-flights/completed-flights.component';
import { ReportsAndDataComponent } from './content/reports-and-data/reports-and-data.component';
import { GeneralDateFormatter } from './shared/utils/general-date-formatter';
import { ContactDataComponent } from './content/contact-data/contact-data.component';
import { ContactComponent } from './content/contact/contact.component';
import { FaqPageComponent } from './content/faq-page/faq-page.component';
import { ChangePasswordComponent } from './content/change-password/change-password.component';
import { ForgotPasswordComponent } from './content/forgot-password/forgot-password.component';

@NgModule({
  declarations: [
    AppComponent,
    TabbarComponent,
    TabbarItemComponent,
    HeaderComponent,
    LoginComponent,
    HomeComponent,
    MainComponent,
    HomeMenuItemComponent,
    UnderConstructionComponent,
    AllFlightsComponent,
    CompletedFlightsComponent,
    ReportsAndDataComponent,
    ContactDataComponent,
    FaqPageComponent,
    ContactComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
  ],
    imports: [
        BrowserModule,
        TurnaroundsModule,
        MessagesModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        AngularFireDatabaseModule,
        AngularFirestoreModule,
        AngularFireFunctionsModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        provideAuth(() => getAuth()),
        provideDatabase(() => getDatabase()),
        provideFirestore(() => getFirestore()),
        FontAwesomeModule,
        SharedModule
    ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, {provide: NgbDateParserFormatter, useClass: GeneralDateFormatter}],
  bootstrap: [AppComponent]
})
export class AppModule { }
