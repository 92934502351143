import { Component, Input, OnInit } from '@angular/core';
import { faHome } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home-menu-item',
  templateUrl: './home-menu-item.component.html',
  styleUrls: ['./home-menu-item.component.scss']
})
export class HomeMenuItemComponent implements OnInit {
  @Input() text: string;
  @Input() icon: string;
  @Input() iconSize: number = 30;
  home = faHome;
  constructor() { }

  ngOnInit(): void {
  }

  protected readonly faHome = faHome;
}
