import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TurnaroundStatus} from '../../../../shared/constants/turnaround-status.constants';
import { ITurnaround } from '../../../../shared/models/turnaround.model';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-turnaround-controls',
  templateUrl: './turnaround-controls.component.html',
  styleUrls: ['./turnaround-controls.component.scss']
})
export class TurnaroundControlsComponent implements OnInit {

  @Input() turnaround: ITurnaround;
  @Input() disabled = false;

  @Output() pauseClicked = new EventEmitter();
  @Output() resumeClicked = new EventEmitter();
  @Output() finishClicked = new EventEmitter();

  taStatus = TurnaroundStatus;

  pauseIcon = faPause;
  playIcon = faPlay;

  constructor() {
  }

  ngOnInit(): void {
  }

  protected readonly faPause = faPause;
  protected readonly faPlay = faPlay;
}
