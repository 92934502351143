import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IGenericContainerObject } from '../../models/genericContainerObject.model';
import { IAcRegistration } from '../../models/ac-registration.model';
import { firstValueFrom } from 'rxjs';
import { UserService } from '../../../services/user.service';
import { NgbActiveModal, NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterService } from '../../../services/filter.service';
import { AcTypesService } from '../../../services/ac-types.service';
import { AcRegistrationsService } from '../../../services/ac-registrations.service';
import { PairService } from '../../../services/pair.service';
import { Filter, FilterCategory } from '../../models/filter.model';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {
  form: FormGroup;
  //acTypes: IGenericContainerObject<IAcType> = {};
  acRegistrations: IGenericContainerObject<IAcRegistration> = {};
  filterCategory: FilterCategory;
  constructor(public modal: NgbActiveModal, private userService: UserService, private modalService: NgbModal, private fb: FormBuilder, private filterService: FilterService, private acTypeService: AcTypesService, private acRegistrationService: AcRegistrationsService, private pairService: PairService) {}

  ngOnInit(): void {
    console.log('Filter Category:', this.filterCategory);
    this.createForm();
    this.getDataForFilter();
  }

  getDataForFilter() {
    this.acRegistrations = this.acRegistrationService.acRegistrations;
        if (this.filterService.filterDataSubject.value?.acRegistration) {
          console.log('Patched acReg:', this.filterService.filterDataSubject.value?.acRegistration);
          this.form.get('acRegistration').setValue(this.filterService.filterDataSubject.value?.acRegistration);
          this.form.get('acRegistration').updateValueAndValidity();
        }
  }


  // get getAvailableAcTypes() {
  //   if (this.form?.value?.acRegistration && this.form?.value?.acRegistration !== 'null') {
  //     return Object.values(this.acTypes).filter((type) => type.id === this.acRegistrations[this.form?.value?.acRegistration?.id].acTypeId);
  //   }
  //   return Object.values(this.acTypes);
  // }

  get getAvailableAcRegistrations() {
    // if (this.form?.value?.acType && this.form?.value?.acType !== 'null') {
    //   return Object.values(this.acRegistrations).filter((registration) => registration.acTypeId === this.form?.value?.acType?.id);
    // }
    return Object.values(this.acRegistrations);
  }

  createForm() {
    this.form = this.fb.group({
      acRegistration: [null],
      acType: [null],
      timespan: [this.filterService.filterDataSubject.value?.timespan || null],
      flightNumber: [this.filterService.filterDataSubject.value?.flightNumber || null],
      passengerGate: [this.filterService.filterDataSubject.value?.passengerGate || null],
      aircraftStand: [this.filterService.filterDataSubject.value?.aircraftStand || null],
      dateFrom: [this.filterService.filterDataSubject.value?.dateFrom || null],
      dateTo: [this.filterService.filterDataSubject.value?.dateTo || null],
    });
  }

  okClicked() {
    const filter = new Filter(this.userService.userSubject.value.location);
    if (this.form.value.acRegistration && this.form.value.acRegistration !== 'null') {
      filter.acRegistration = this.form.value.acRegistration;
    }
    if (this.form.value.passengerGate) {
      filter.passengerGate = this.form.value.passengerGate;
    }
    if (this.form.value.flightNumber) {
      filter.flightNumber = this.form.value.flightNumber;
    }
    if (this.form.value.aircraftStand) {
      filter.aircraftStand = this.form.value.aircraftStand;
    }
    if (this.form.value.timespan) {
      filter.timespan = this.form.value.timespan;
    }
    if (this.form.value.dateFrom) {
      filter.dateFrom = this.form.value.dateFrom;
    }
    if (this.form.value.dateTo) {
      filter.dateTo = this.form.value.dateTo;
    }
    this.filterService.setFilter(filter);
    this.modal.close('refresh');
  }

  public readonly FilterCategory = FilterCategory;
}
