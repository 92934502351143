import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {FunctionService} from '../../../services/function.service';
import {UserService} from '../../../services/user.service';
import { IMemoModel } from '../../models/memo.model';
import { MemosService } from '../../../services/memos.service';
import { IPairDetail } from '../../models/pair-detail.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IUser } from '../../models/user.model';

@Component({
  selector: 'app-ramp-view-memo',
  templateUrl: './ramp-view-memo.component.html',
  styleUrls: ['./ramp-view-memo.component.scss']
})
export class RampViewMemoComponent implements OnInit, OnDestroy {

  @Output() closeModalAction = new EventEmitter<string>();
  activeId = 1;
  pair: IPairDetail;
  arrivalMemo: IMemoModel;
  departureMemo: IMemoModel;
  arrivalMemoUser: IUser;
  departureMemoUser: IUser;

  user: any;

  form: FormGroup;
  isBusy = false;
  unsubscribe$ = new Subject();

  constructor(
    private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private functionService: FunctionService,
    private userService: UserService,
    private memoService: MemosService) {
  }

  ngOnInit(): void {
        this.getUser();

    if (!this.pair) {
      return;
    }

    if(this.pair.arrivalMemos.length) {
      this.arrivalMemo = this.pair.arrivalMemos[0];
      this.userService.getUserById(this.arrivalMemo.lastChangedBy).subscribe((user) => {
        this.arrivalMemoUser = user;
      });
    }
    if(this.pair.departureMemos.length) {
      this.departureMemo = this.pair.departureMemos[0];
      this.userService.getUserById(this.departureMemo.lastChangedBy).subscribe((user) => {
        this.departureMemoUser = user;
      });
    }

    const arrivalText = this.pair.arrivalMemos.length ? this.pair.arrivalMemos[0].content || '' : '';
    const departureText = this.pair.departureMemos.length ? this.pair.departureMemos[0].content || '' : '';

    this.form = this.fb.group({
      arrivalText: [arrivalText],
      departureText: [departureText]
    });
    this.setActiveId(1);
  }

  setActiveId(id: number) {
    this.activeId = id;
    switch(id) {
      case 1:
        this.form.get('arrivalText').setValidators(Validators.required);
        this.form.get('departureText').removeValidators(Validators.required);
        break;
      case 2:
        this.form.get('arrivalText').removeValidators(Validators.required);
        this.form.get('departureText').setValidators(Validators.required);
        break;
    }
    this.form.get('arrivalText').updateValueAndValidity();
    this.form.get('departureText').updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  getUser() {
    this.userService.getUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => this.user = user);
  }

  saveClicked() {
    if (this.form.invalid) {
      console.log('form field is not available or invalid');
      return;
    }
    if (this.isBusy) {
      return;
    }
    this.isBusy = true;
    const newText = this.activeId === 1 ? this.form.get(`arrivalText`).value : this.form.get(`departureText`).value;
    let id;
    if(this.activeId === 1 && this.pair?.arrivalMemos?.length) {
      id = this.pair.arrivalMemos[0].id;
    } else if (this.activeId === 2 && this.pair?.departureMemos?.length) {
      id = this.pair.departureMemos[0].id;
    }

    this.memoService.saveMemo({
      content: newText,
      legId: this.activeId === 1 ? this.pair?.arrivalLeg?.id : this.pair?.departureLeg?.id,
      pairId: this.pair?.id,
      id,
      userGroupId: 1,
    }).subscribe((result) => {
      this.isBusy = false;
      if(result) {
        this.closeModal('refresh');
      }
    });
  }

  deleteClicked() {
    if (this.isBusy) {
      return;
    }
    this.isBusy = true;
    let id = this.activeId === 1 ? this.arrivalMemo.id : this.departureMemo.id;
    this.memoService.deleteMemo(id).subscribe((res) => {
      if(res) {
        this.closeModal('refresh');
      }
      this.isBusy = false;
    });
  }

  get username(): string {
    return this.user ? `${this.user.firstname} ${this.user.lastname}` : 'unknown';
  }

  closeModal(reason: string) {
    this.activeModal.dismiss(reason);
  }
}
