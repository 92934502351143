import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import * as dayjs from 'dayjs';
import { IPairDetail } from '../../../shared/models/pair-detail.model';

@Component({
  selector: 'app-turnarounds-pair-list',
  templateUrl: './turnarounds-pair-list.component.html',
  styleUrls: ['./turnarounds-pair-list.component.scss']
})
export class TurnaroundsPairListComponent implements OnInit, OnDestroy {
  @Input() pairList: any[] = [];
  @Input() isFirestore: boolean;
  @Input() isBusy?: boolean;

  unsubscribe$ = new Subject();

  constructor() {
  }

  ngOnInit(): void {

  }

  pairTrackBy(index: number, pair: IPairDetail) {
    return pair.id;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  datesNotSameDay(d1: string, d2: string): boolean {
    if (!d1 || !d2) {
      return false;
    }

    const date1 = dayjs(d1);
    const date2 = dayjs(d2);

    return date1 && date1.isValid() && date2 && date2.isValid() && date1.date() !== date2.date();
  }
}
