import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as dayjs from 'dayjs';
import { IUser } from '../../../../shared/models/user.model';
import { IMyChatGroups } from '../../../../shared/models/my-chat-groups.interface';
import { UserRoles } from '../../../../shared/constants/user-roles.constants';

@Component({
  selector: 'app-message-group-list-item',
  templateUrl: './message-group-list-item.component.html',
  styleUrls: ['./message-group-list-item.component.scss']
})
export class MessageGroupListItemComponent {

  @Input() selectedMessageGroupKey: number;

  @Output() setMessageGroupSelection = new EventEmitter<number>();

  // New
  @Input() user: IUser;
  @Input() chatGroupId: number;
  @Input() numberOfUnreadMessages: number = 0;
  @Input() chatGroup: IMyChatGroups;
  userRoleObject = UserRoles;
  constructor() {
  }

  isToday(datetime: Date): boolean {
    const now = dayjs().utc();
    const toCheck = dayjs(datetime).utc();

    return now && toCheck && now.isValid() && toCheck.isValid() && now.isSame(toCheck, 'day');
  }

  showMessageGroupDetails() {
    if (!this.chatGroupId) {
      return;
    }

    //this.setMessageGroupSelection.emit(this.chatGroupId);
  }

}
