<div class="modal-header">
  <h4 class="modal-title poppins">Edit message</h4>
  <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body" [formGroup]="formGroup">
  <div class="flex gap-2 justify-content-center">
    <input class="form-control" formControlName="content">
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn custom-button-1 settings-modal-button" (click)="editMessage()"><span>Submit</span></button>
  <button type="button" class="btn custom-button-1 settings-modal-button" (click)="closeModal()"><span>Close</span>
  </button>
</div>
