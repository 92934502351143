<div class="controls-container">
  <button [disabled]="disabled" *ngIf="turnaround?.currentState === taStatus.IN_PROGRESS || turnaround?.currentState === taStatus.RESUMED" class="btn button-blue d-flex gap-2" (click)="finishClicked.emit()">
    <div class="icon-completed-flights-white" [style]="{ width: '16px', height: '16px' }"></div>
    <span class="roboto-font">Finish</span></button>
  <button [disabled]="disabled" *ngIf="turnaround?.currentState === taStatus.IN_PROGRESS || turnaround?.currentState === taStatus.RESUMED" class="btn button-blue d-flex gap-2" (click)="pauseClicked.emit()">
    <fa-icon [icon]="faPause" [styles]="{ color: 'white' }"></fa-icon>
    <span class="roboto-font">Pause</span></button>
  <button [disabled]="disabled" *ngIf="turnaround?.currentState === taStatus.PAUSED" class="btn button-blue d-flex gap-2" (click)="resumeClicked.emit()">
    <fa-icon [icon]="faPlay" [styles]="{ color: 'white' }"></fa-icon>
    <span class="roboto-font">Resume</span></button>
</div>
