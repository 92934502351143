import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {TurnaroundStatus} from '../../../../shared/constants/turnaround-status.constants';
import { IPairDetail } from '../../../../shared/models/pair-detail.model';
import * as dayjs from 'dayjs';
import { IPairOverview, isPairOverview } from '../../../../shared/models/pair-overview.model';
import { getLatestArrivalTime } from '../../../../shared/utils/utils';

@Component({
  selector: 'app-turnarounds-pair-list-item',
  templateUrl: './turnarounds-pair-list-item.component.html',
  styleUrls: ['./turnarounds-pair-list-item.component.scss']
})
export class TurnaroundsPairListItemComponent implements OnInit, OnChanges {

  @Input() pair: IPairDetail | IPairOverview;
  @Input() isFirestore: boolean;
  isOverview = isPairOverview;

  taStatus = TurnaroundStatus;
  turnaroundTime: number;
  timezone = '+00:00';
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pair?.currentValue) {
      if (!isPairOverview(this.pair)) {
        if (!this.pair.departureLeg || !this.pair.arrivalLeg) {
          return;
        }
        this.turnaroundTime = dayjs.utc(this.pair.departureLeg?.tod).diff(dayjs.utc(this.pair.arrivalLeg?.toa), 'minutes');
        return;
      }
      this.turnaroundTime = (this.pair as IPairOverview).groundTime;
    }
  }

  ngOnInit(): void {


  }

  calcDateDifferenceInDays(date1: Date, date2: Date, tz: string): number {
    if (!date1 || !date2) {
      return 0;
    }

    let d1 = dayjs(date1);
    let d2 = dayjs(date2);
    if (d1 && d2 && d1.isValid() && d2.isValid()) {
      if (tz && tz.length === 5) {
        const hours = Number(tz.slice(1, 3));
        const minutes = Number(tz.slice(3, 5)) + hours * 60;
        d1 = d1.utcOffset(minutes);
        d2 = d2.utcOffset(minutes);
      } else if (tz && tz.length === 6) {
        const hours = Number(tz.slice(1, 3));
        const minutes = Number(tz.slice(4, 6)) + hours * 60;
        d1 = d1.utcOffset(minutes);
        d2 = d2.utcOffset(minutes);
      }
      d1 = d1.startOf('day');
      d2 = d2.startOf('day');
      return Math.abs(d2.diff(d1, 'd'));
    }
    return 0;
  }

  calcDateDifferenceString(date1: Date, date2: Date, tz: string): string {
    const res = this.calcDateDifferenceInDays(date1, date2, tz);
    if (res === 0) {
      return '';
    }
    const sign = dayjs.utc(date1).isAfter(date2) ? '-' : '+';
    return sign + res;
  }

  get isArrivalLegEta(): boolean {
    if (isPairOverview(this.pair)) {
      return this.pair && !!this.pair.eta;
    }
    return this.pair && this.pair.arrivalLeg && !!this.pair.arrivalLeg.eta;
  }

  get isArrivalLegLanding(): boolean {
    if (isPairOverview(this.pair)) {
      return this.pair && !!this.pair.landingTime
    }
    return this.pair && this.pair.arrivalLeg && !!this.pair.arrivalLeg.landingTime;
  }

  get isArrivalLegAta(): boolean {
    if (isPairOverview(this.pair)) {
      return this.pair && !!this.pair.ata;
    }
    return this.pair && this.pair.arrivalLeg && !!this.pair.arrivalLeg.ata;
  }

  get hasArrivalAndDepartureLeg(): boolean {
    if (isPairOverview(this.pair)) {
      return this.pair && !!this.pair.arrivalLegId && !!this.pair.departureLegId;
    }
    return this.pair && this.pair.arrivalLeg && !!this.pair.departureLeg;
  }


  protected readonly getLatestArrivalTime = getLatestArrivalTime;
  protected readonly isPairOverview = isPairOverview;
}
