import { Component } from '@angular/core';
import { UserService } from './services/user.service';
import { Subject } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { takeUntil } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as duration from 'dayjs/plugin/duration';
import * as arraySupport from 'dayjs/plugin/arraySupport';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'RampWatchMobilePWA';
  user: any;
  unsubscribe$ = new Subject();

  constructor(private swUpdate: SwUpdate, private userService: UserService) {
    dayjs.extend(utc);
    dayjs.extend(isSameOrBefore);
    dayjs.extend(isSameOrAfter);
    dayjs.extend(customParseFormat);
    dayjs.extend(duration);
    dayjs.extend(arraySupport);
  }

  ngOnInit(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate().then((hasUpdate) => {
        if (!hasUpdate) {
          return;
        }
        if (confirm('New version available. Load New Version?')) {
          this.swUpdate.activateUpdate().then(() => {
            window.location.reload();
          }).catch(() => null);
        }
      }).catch(() => null);
      //
      // this.swUpdate.available.subscribe(() => {
      //
      //   if (confirm('New version available. Load New Version?')) {
      //
      //     window.location.reload();
      //   }
      // });
    }

    this.fetchUser();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  fetchUser() {
    this.userService.getUser().pipe(takeUntil(this.unsubscribe$)).subscribe((user: any) => this.user = user);
  }
}
