import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-extend-time',
  templateUrl: './extend-time.component.html',
  styleUrls: ['./extend-time.component.scss']
})
export class ExtendTimeComponent implements OnInit {

  @Input() form: FormGroup;
  @Output() extendClicked = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  onExtendClicked() {
    if (this.form && this.form.get('time') && this.form.get('time').valid) {
      this.extendClicked.emit(this.form.value);
    }
  }

}
