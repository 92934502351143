import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IUser } from '../../models/user.model';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { IChatMessageModel } from '../../models/chat-message.model';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { ChatService } from '../../../services/chat.service';
import { firstValueFrom } from 'rxjs';
import { ToastService } from '../../../services/toast.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-chat-session-form',
  templateUrl: './chat-session-form.component.html',
  styleUrls: ['./chat-session-form.component.scss']
})
export class ChatSessionFormComponent implements OnInit {

  @Input() chatGroupId: number;
  @Input() user: IUser;
  @Input() conversation: IChatMessageModel[];
  @Output() chatGroupIdChange = new EventEmitter<number>();

  formGroup = new FormGroup({
    text: new FormControl('')
  });

  likeIcon: IconDefinition = faThumbsUp;

  constructor(private chatService: ChatService, private userService: UserService, private toastService: ToastService) {
  }

  ngOnInit(): void {
  }

  async submit() {
    const message = this.formGroup.get('text').value?.trim().length ? this.formGroup.get('text').value?.trim() : '👍';
    this.formGroup.reset();
    if (!this.chatGroupId) {
      const chatGroupId = await firstValueFrom(this.chatService.createChatGroup([this.userService.userSubject?.value?.id, this.user.id]));
      if (!chatGroupId) {
        return;
      }
      this.chatGroupId = chatGroupId;
      this.chatGroupIdChange.emit(chatGroupId);
    }
    this.chatService.sendMessage(this.chatGroupId, message).subscribe((result) => {
      if (!result.id) {
        this.toastService.showError('There was a problem sending your message. Please try again');
      }
      else {
        this.conversation?.unshift(result);
      }
    });
  }

  submitKeyboard(evt: KeyboardEvent) {
    if(evt.key === 'Enter') {
      this.submit();
    }
  }
}
