<div *ngFor="let messageGroup of shownMessageGroups" class="col-12 p-0">
  <app-message-group-list-item
    [user]="messageGroup?.__user__"
    [chatGroupId]="messageGroup?.chatGroupId"
    [numberOfUnreadMessages]="myGroups[messageGroup?.chatGroupId]?.numberOfUnreadMessages"
    [chatGroup]="messageGroup"
    [selectedMessageGroupKey]="messageGroupKeySelection"
    (setMessageGroupSelection)="setSelectedMessageGroup($event)"
    routerLink="/chat/{{messageGroup.chatGroupId}}"></app-message-group-list-item>
</div>
