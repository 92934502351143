import { Injectable, OnDestroy } from '@angular/core';
import { UserService } from './user.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IFilter } from '../shared/models/filter.model';

@Injectable({
  providedIn: 'root'
})
export class FilterService implements OnDestroy {

  filterData: IFilter = {
    station: '',
    acRegistration: null,
    acType: null,
    timespan: null,
    dateFrom: null,
    dateTo: null,
    aircraftStand: null,
    passengerGate: null,
    flightNumber: null,
    completedOnly: null,
  };

  filterDataSubject = new BehaviorSubject(this.filterData);

  unsubscribe$ = new Subject();

  constructor(private userService: UserService) {
    this.userService.getUser().pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        this.filterData.station = user ? user.location : null;
        this.filterDataSubject.next(this.filterData);
      });
  }

  getFilterData() {
    return this.filterDataSubject.asObservable();
  }

  setFilter(newFilter: IFilter) {
    this.filterData = newFilter;
    this.filterDataSubject.next(this.filterData);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }
}
