<div class="modal-header">
  <div class="modal-title">Select user to chat</div>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
</div>
<div class="modal-body">
  <div class="d-flex flex-column">
<!--    <button class="btn btn-primary" *ngFor="let user of userList" (click)="modal.dismiss(user)">{{ user.firstname }} {{user.lastname}}</button>-->

    <div class="custom-row" *ngFor="let user of userList" (click)="modal.dismiss(user)">
      <div class="d-flex gap-3" >
        <app-user-photo class="align-self-center"  [user]="user" [size]="40" circleBackground="#F2F2F2" textColor="black"></app-user-photo>
        <div class="d-flex flex-column">
          <div class="roboto-font dark-blue">{{ user.firstname }} {{ user.lastname }}</div>
          <div class="roboto-font dark-blue">{{ user.role | i18nSelect: userRoleObject.DISPLAY_TITLE_MAP }} {{ user.location }}</div>
        </div>
        <div class="ms-auto align-self-center"><i class="demo-icon icon-icons8-forward dark-blue"></i></div>
      </div>
    </div>
  </div>
</div>
<!--<div class="modal-footer">-->
<!--  <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>-->
<!--</div>-->
