<div class="faq-container p-3 d-flex flex-column gap-3">
  <ol>
    <li>
      <div class="d-flex flex-column">
        <div class="question">How can I check the turnarounds into my station?</div>
        <div class="answer">By tapping on the "Day of Operation" icon you will be able to see the turnarounds for today.</div>
      </div>
    </li>

    <li>
      <div class="d-flex flex-column">
        <div class="question">What is the "All Flights" icon?</div>
        <div class="answer">All Flights option will show you turnarounds at your station on the day, but also by using the filter you can set a past/future period for turnarounds to appear.</div>
      </div>
    </li>

    <li>
      <div class="d-flex flex-column">
        <div class="question">How can I see past turnarounds?</div>
        <div class="answer">The "Completed Flights" icon can be used to check information on completed turnarounds. A turnaround is considered as completed when an ATD is entered into the departing sector of the turnaround.</div>
      </div>
    </li>

    <li>
      <div class="d-flex flex-column">
        <div class="question">Can I chat via the avitium RampWatch mobile app?</div>
        <div class="answer">Yes, by tapping either onto the "Chat & Messages" icon or onto the envelope at the footer menu you may enter the chat and start a conversation or respond to a message from a colleague.</div>
      </div>
    </li>

    <li>
      <div class="d-flex flex-column">
        <div class="question">How can I check the status of an inbound flight?</div>
        <div class="answer">In the "Day of Operation" option, tap on a the flight pairing (of the inbound flight). In the screen that opens under the "Arriving Flight" you can can check the actual status of the sector.</div>
      </div>
    </li>

    <li>
      <div class="d-flex flex-column">
        <div class="question">What do the green/amber/red triangles at the top right-hand corner of the flight pairing mean?</div>
        <div class="answer">Based on either the normal or the minimum turnaround time for this flight pairing avitium RampWatch prompts you on the turnaround status. Green means that there is adequate turnaround time, amber that the time available is more than the minimum but critical, and red means that the minimum turnaround time is violated.</div>
      </div>
    </li>

    <li>
      <div class="d-flex flex-column">
        <div class="question">How can I start a turnaround?</div>
        <div class="answer">Tap on the "Day of Operation" icon, on a turnaround that you want to start and press the button "Start Turnaround".</div>
      </div>
    </li>

    <li>
      <div class="d-flex flex-column">
        <div class="question">If the turnaround time is not enough can I request for extension?</div>
        <div class="answer">Yes you can. In the turnaround in question, enter the extension (in minutes) that you require and press "Request". A request for extension will appear to the Airport Duty Manager who then either grant or deny this request. In the meantime a rolling "awaiting" circle will appear into your screen.</div>
      </div>
    </li>

    <li>
      <div class="d-flex flex-column">
        <div class="question">How can I finish a turnaround?</div>
        <div class="answer">By pressing the "Finish" button in the turnaround status screen.</div>
      </div>
    </li>

    <li>
      <div class="d-flex flex-column">
        <div class="question">In case there is an arrival of a flight but the departure is scheduled to be some hours later (e.g. overnight flights), how can I manage these cases?</div>
        <div class="answer">For cases like that where some turnaround procedures are commencing during the arrival but others during the departure (e.g. de-boarding, boarding) you may start the turnaround and then press the "Pause" button. When the departure procedures need to commence you may re-activate the turnaround by pressing the "Resume" button.</div>
      </div>
    </li>

    <li>
      <div class="d-flex flex-column">
        <div class="question">How do you calculate the amount of Turnarounds in the Report sections?</div>
        <div class="answer">The amount of turnarounds is determined and assigned to the user who clicks finish turnaround. The turnaround time is the time period between hitting the "start turnaround" button and the "finish". If the turnaround has been paused this is taken under consideration.</div>
      </div>
    </li>

    <li>
      <div class="d-flex flex-column">
        <div class="question">Can I chat with Users from other stations?</div>
        <div class="answer">At the moment you are able to chat only with users from your station (Duty Managers and Ramp Agents).</div>
      </div>
    </li>

  </ol>

</div>
