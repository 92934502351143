import { Component, OnInit } from '@angular/core';
import { RoutableModel } from '../../shared/models/routable.model';

@Component({
  selector: 'app-under-construction',
  templateUrl: './under-construction.component.html',
  styleUrls: ['./under-construction.component.scss']
})
export class UnderConstructionComponent implements OnInit, RoutableModel {
  backUrl: string = '/';
  canGoBack: boolean = true;
  pageTitle: string = 'Under Construction';


  constructor() { }

  ngOnInit(): void {
  }

}
