<div *ngIf="!isOwnMessage" class="message-item-container">
  <div class="sender">
    {{firstname}}
  </div>
  <div class="d-flex flex-row">
    <div class="message-container rounded-lg">
      <div class="message-text">
        {{text}}
      </div>
    </div>
    <i class="demo-icon icon-icons8-more options"></i>
  </div>
</div>

<div *ngIf="isOwnMessage" class="own-message-item-container">
  <div class="d-flex flex-row justify-content-end">
    <i class="demo-icon icon-icons8-more options"></i>
    <div class="message-container rounded-lg avitium-bg-color">
      <div class="message-text text-white">
        {{text}}
      </div>
    </div>
  </div>
</div>
