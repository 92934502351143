import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as dayjs from 'dayjs';
import { IUser } from '../../models/user.model';
import { firstValueFrom, interval, Observable, Subscription } from 'rxjs';
import { ChatService } from '../../../services/chat.service';
import { UserService } from '../../../services/user.service';
import { TimezoneService } from '../../../services/timezone.service';
import { IChatMessageModel } from '../../models/chat-message.model';

@Component({
  selector: 'app-chat-session',
  templateUrl: './chat-session.component.html',
  styleUrls: ['./chat-session.component.scss']
})
export class ChatSessionComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('conversationContainer') conversationContainer: HTMLElement;
  @Input() chatGroupId: number;
  @Input() user: IUser;
  @Output() chatGroupIdChange = new EventEmitter<number>();
  fetchHistorySubInterval: Subscription;
  @Input() conversation: IChatMessageModel[] = [];
  timezone$: Observable<string>;
  isLoading = true;

  constructor(private chatService: ChatService, private tzService: TimezoneService, public userService: UserService) {
  }

  dateDiff(date1: Date, date2: Date): number {
    return Math.abs(dayjs(date1).diff(date2, 'minutes'));
  }

  ngOnInit() {
    this.timezone$ = this.tzService.getTimezone();
    if (!this.chatGroupId) {
      this.isLoading = false;
    }
  }

  ngOnDestroy() {
    if (this.fetchHistorySubInterval) {
      this.fetchHistorySubInterval.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.chatGroupId) {
      this.fetchHistorySubInterval?.unsubscribe();
      this.getHistory().then(() => {
        this.fetchHistorySubInterval = interval(5000).subscribe(() => {
          this.getHistory();
        });
      });
    }
  }

  async getHistory() {
    const conversationData = await firstValueFrom(this.chatService.getChatHistory(this.chatGroupId, this.conversation.length ? this.conversation[0].id : undefined));
    for (let i=0; i < conversationData.length; i++) {
      if (this.conversation.find((item) => item.id === conversationData[i].id)) {
        conversationData.splice(i, 1);
        i--;
      }
    }
    conversationData.reverse();
    this.conversation.unshift(...conversationData);
    this.isLoading = false;
  }
}
