import {Component, Input, OnInit} from '@angular/core';
import {IExtendTurnaroundRequest} from '../../../../shared/models/extendTurnaroundRequest.model';

@Component({
  selector: 'app-turnaround-time',
  templateUrl: './turnaround-time.component.html',
  styleUrls: ['./turnaround-time.component.scss']
})
export class TurnaroundTimeComponent implements OnInit {

  @Input() groundTimeInMinutes: number;
  // @Input() extendRequest: IExtendTurnaroundRequest | null;

  _lastExtendRequest: IExtendTurnaroundRequest | null;

  get lastExtendRequest(): IExtendTurnaroundRequest | null {
    return this._lastExtendRequest;
  }

  @Input('lastExtendRequest')
  set lastExtendRequest(val: IExtendTurnaroundRequest | null) {
    /*
    console.log('new value', val);
    if (this._extendRequest && !val) {
      this._extendRequest = val;
      this.animationState = 'start';
      console.log('start');
      setTimeout(() => {
        console.log('stop');
        this.currentRequest = this._extendRequest;
        this.animationState = 'stop';
      }, 5000);

    } else {
      this._extendRequest = val;
      if (this.animationState !== 'start') {
        this.currentRequest = this._extendRequest;
      }
    } */
    console.log('new request 1: ', val);
    console.log('extend request 1: ', this._lastExtendRequest);
    console.log('current request 1: ', this.currentRequest);
    this.currentRequest = this._lastExtendRequest = val;
    console.log('new request 2: ', val);
    console.log('extend request 2: ', this._lastExtendRequest);
    console.log('current request 2: ', this.currentRequest);
  }

  currentRequest: IExtendTurnaroundRequest | null;
  animationState = 'stop';

  constructor() {
  }

  ngOnInit(): void {
  }

}
