<ngb-alert [dismissible]="false" *ngIf="noUsersAlert">
  No users found to chat for this station.
</ngb-alert>
<div *ngIf="pair" class="list-container mt-3">
  <div class="row filter-container">
    <div class="w-auto" (click)="openViewMemo()">
      Memo
    </div>
    <div class="w-auto" (click)="openViewPairChat()">
      Chat
    </div>
  </div>
  <div class="ms-2 mt-3 mb-2 dateContainer roboto-font dark-blue">
    {{pair.dateOfOperation | date: 'dd.MM.yyyy'}}
  </div>
  <div class="my-3 d-flex flex-column gap-3">
    <app-turnarounds-pair-list-item [pair]="pair"></app-turnarounds-pair-list-item>
  </div>
  <div class="turnaround-time-container" *ngIf="pair.turnaround?.currentState === turnaroundStatus.IN_PROGRESS || pair.turnaround?.currentState === turnaroundStatus.RESUMED">
    <app-turnaround-time [groundTimeInMinutes]="groundTime" [lastExtendRequest]="pair?.turnaround?.lastExtendEtdRequest"></app-turnaround-time>
  </div>
  <nav class="navbar navbar-dark flex-down">
      <ul class="list-horizontal text-white float-start roboto-font">
        <li [ngClass]="{ active: activeId === 1 }" (click)="activeId = 1">All</li>
        <li [ngClass]="{ active: activeId === 2 }" (click)="activeId = 2">Arriving</li>
        <li [ngClass]="{ active: activeId === 3 }" (click)="activeId = 3">Departing</li>
        <li [ngClass]="{ active: activeId === 4 }" (click)="activeId = 4" *ngIf="isFirestore && !pair?.departureLeg?.atd">Hide</li>
      </ul>
  </nav>
  <div class="c-tab-content">
    <div *ngIf="activeId === 1 || activeId === 2">
      <app-pair-detail-leg [leg]="pair.arrivalLeg" [isArrivalLeg]="true"></app-pair-detail-leg>
    </div>
    <div *ngIf="activeId === 1 || activeId === 3" [ngClass]="{ 'mt-3': activeId === 1 }">
      <app-pair-detail-leg [leg]="pair.departureLeg" [isArrivalLeg]="false"></app-pair-detail-leg>
    </div>
    <div *ngIf="isFirestore && !pair?.departureLeg?.atd">
      <div *ngIf="!pair?.turnaround || pair.turnaround.currentState === turnaroundStatus.UPCOMING" class="start-container">
        <app-start-turnaround [disabled]="isBusy" (startClicked)="startTurnaround()"></app-start-turnaround>
        <ng-container *ngIf="pair?.turnaround && pair.turnaround.currentState !== turnaroundStatus.UPCOMING">
          <div class="remaining-time-container border-bottom-gray bg-white">
            <app-remaining-time [turnaroundData]="pair?.turnaround" [tod]="pair?.departureLeg?.tod"></app-remaining-time>
          </div>
          <div *ngIf="pair?.turnaround?.currentState !== turnaroundStatus.FINISHED" class="extend-time-container border-bottom-gray bg-white">
            <app-extend-time [form]="form" (extendClicked)="openExtendForm(content)"></app-extend-time>
          </div>
          <div class="turnaround-controls-container">
            <app-turnaround-controls
              [disabled]="isBusy"
              [turnaround]="pair?.turnaround"
              (pauseClicked)="pauseTurnaround()"
              (resumeClicked)="resumeTurnaround()"
              (finishClicked)="finishTurnaround()"
            ></app-turnaround-controls>
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="pair?.turnaround && pair.turnaround.currentState !== turnaroundStatus.UPCOMING">
        <div class="remaining-time-container mt-3">
          <app-remaining-time [turnaroundData]="pair?.turnaround" [tod]="pair?.departureLeg?.tod"></app-remaining-time>
        </div>
        <div *ngIf="pair?.turnaround?.currentState !== turnaroundStatus.FINISHED" class="extend-time-container mt-3">
          <app-extend-time #extendTime [form]="form" (extendClicked)="openExtendForm(content)"></app-extend-time>
        </div>
        <div class="turnaround-controls-container">
          <app-turnaround-controls
            [disabled]="isBusy || form?.value?.time !== null && form?.value?.time != 0"
            [turnaround]="pair?.turnaround"
            (pauseClicked)="pauseTurnaround()"
            (resumeClicked)="resumeTurnaround()"
            (finishClicked)="finishTurnaround()"
          ></app-turnaround-controls>
        </div>
      </ng-container>
    </div>
  </div>
</div>


<ng-template #content let-modal>
  <div class="modal-header poppins dark-blue">
    <div class="modal-title">Request turnaround time extension</div>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-group">
        <label for="reasonForExtension" class="roboto-font dark-blue">Reason for extension*</label>
        <div class="input-group">
          <textarea id="reasonForExtension" formControlName="reason" rows="4" ngbAutofocus class="roboto-font dark-blue" placeholder="Extension reason"></textarea>
        </div>
        <div class="button-container align-self-end col-auto">
          <button type="button" class="btn button-blue" [disabled]="form?.invalid" (click)="modal.close('Save click')">Submit request</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
