<div *ngIf="!ownMessage" class="message-item-container roboto-font dark-blue">
  <div class="d-flex flex-row">
    <div class="message-container rounded-lg deleted" *ngIf="!message.isActive">
      <div class="message-text text-muted">
        This message has been deleted
      </div>
    </div>
    <div class="message-container rounded-lg" *ngIf="message.isActive">
      <div class="message-text" *ngIf="message.content !== '👍'">
        {{ message.content }}
        <span *ngIf="message.createdAt !== message.lastChangedAt" class="edited">(edited)</span>
      </div>
      <div class="like" *ngIf="message.content === '👍'">
        <fa-icon [icon]="thumbsUpIcon" class="avitium-color" size="3x"></fa-icon>
        <span *ngIf="message.createdAt !== message.lastChangedAt" class="edited">(edited)</span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="ownMessage" class="own-message-item-container roboto-font dark-blue">
  <div class="d-flex flex-row">

    <div ngbDropdown class="d-flex justify-content-center float-end cursor-pointer">
      <fa-icon class="align-self-center" [icon]="ellipsisIcon" [classes]="['align-self-center']" [styles]="{ 'color': '#a6a6a6', 'margin-right': '10px' }" ngbDropdownToggle></fa-icon>
      <div ngbDropdownMenu>
        <button ngbDropdownItem (click)="editMessage()">Edit message</button>
        <button ngbDropdownItem (click)="deleteMessage()">Delete message</button>
      </div>
    </div>
    <div class="message-container rounded-lg deleted" *ngIf="!message.isActive">
      <div class="message-text text-muted">
        This message has been deleted
      </div>
    </div>
    <div class="message-container rounded-lg my-message" *ngIf="message.isActive">
      <div class="" *ngIf="message.content !== '👍'">
        {{ message.content }}
        <span *ngIf="message.createdAt !== message.lastChangedAt" class="edited">(edited)</span>
      </div>
      <div class="like" *ngIf="message.content === '👍'">
        <fa-icon [icon]="thumbsUpIcon" class="avitium-color" size="3x"></fa-icon>
        <span *ngIf="message.createdAt !== message.lastChangedAt" class="edited">(edited)</span>
      </div>
    </div>
  </div>
</div>
