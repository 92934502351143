import { Component } from '@angular/core';
import { RoutableModel } from '../../shared/models/routable.model';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FirebaseError } from '@angular/fire/app';
import * as auth from 'firebase/auth';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements RoutableModel {
  backUrl: string = null;
  canGoBack: boolean = true;
  pageTitle: string = 'Change Password';
  form: FormGroup = new FormGroup({
    newPassword: new FormControl('', [Validators.required, Validators.minLength(4)]),
    newPassword2: new FormControl('', [Validators.required, Validators.minLength(4)]),
  },
    this.passwordValidation
  );
  reauthForm: FormGroup;
  isBusy = false;
  error: string = '';
  changed: boolean = false;

  constructor(private auth: AngularFireAuth) { }

  async changePassword() {
    if (this.form.invalid) {
      return;
    }
    this.isBusy = true;
    this.updatePass().then(() => this.changed = true).catch((err) => {
      console.log(err);
      if (err instanceof FirebaseError) {
        switch(err.code) {
          case 'auth/weak-password':
            this.error = err.message.split('(')[0];
            break;
          case 'auth/requires-recent-login':
            this.reauthForm = new FormGroup({
              email: new FormControl('', Validators.required),
              password: new FormControl('', [Validators.required, Validators.minLength(6)])
            });
            this.error = err.message.split('(')[0];
            break;
        }
      }
    }).finally(() => this.isBusy = false);
  }

  async updatePass() {
    const user = await this.auth.currentUser;
    if (!user) {
      return;
    }
    return user.updatePassword(this.form.value.newPassword);
  }

  async reauthenticateUser(email: string, password: string) {
    const credentials = auth.EmailAuthProvider.credential(email, password);
    const user = await this.auth.currentUser;
    if (user) {
      return user.reauthenticateWithCredential(credentials);
    }
  }

  passwordValidation(formGroup: FormGroup): ValidatorFn {
    const newPass1 = formGroup.get('newPassword');
    const newPass2 = formGroup.get('newPassword2');

    // Perform validation logic
    if (newPass1 && newPass2 && newPass1.value !== newPass2.value) {
      newPass1.setErrors({ password: true });
      newPass2.setErrors({ password: true });
    } else {
      if(newPass1) {
        if (newPass1.hasError('password')) {
          newPass1.setErrors(null);
        }
      }
      if (newPass2) {
        if (newPass2.hasError('password')) {
          newPass2.setErrors(null);
        }
      }

    }
    return null;
  }

  async reauthAndChangePassword() {
    if (this.reauthForm?.invalid) {
      return;
    }
    this.isBusy = true;
    const res = await this.reauthenticateUser(this.reauthForm.value.email, this.reauthForm.value.password).catch((err) => {
      if (err instanceof FirebaseError) {
        switch(err.code) {
          case 'auth/wrong-password':
            this.error = 'Your password is incorrect. Please try again.';
            break;
        }
      }
      return null;
    });
    this.isBusy = false;
    if (res) {
      this.error = '';
      this.reauthForm = null;
      this.changePassword();
    }
  }
}
