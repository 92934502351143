<div class="remaining-time-container">
  <span class="roboto-font fw-bold dark-blue">Remaining time:</span>
  <div class="progress-wrapper">
    <div class="progress text-center">
      <span *ngIf="turnaroundData?.currentState === taState.IN_PROGRESS || turnaroundData?.currentState === taState.RESUMED" class="height-20 turnaround-time-text roboto-font dark-blue">{{remainingTimeInMinutes}} min</span>
      <span *ngIf="turnaroundData?.currentState && (turnaroundData.currentState === taState.PAUSED || turnaroundData.currentState === taState.FINISHED)"
         class="height-20 turnaround-time-text roboto-font dark-blue">{{turnaroundData.currentState | uppercase}}</span>
    </div>
    <round-progress [current]="percentage" [max]="totalTimeInMinutes" color="#4a62adx" rounded="true" [radius]="80" class="path-color"
                    stroke="17"

                    [ngClass]="{
                    'yellow': turnaroundData?.currentState && turnaroundData.currentState === taState.PAUSED,
                    'green': turnaroundData?.currentState && turnaroundData.currentState === taState.FINISHED
                    }"
    ></round-progress>
  </div>
</div>
