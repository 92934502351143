<div class="item-container">
  <div class="menu-icon flex-basis-50">
    <img *ngIf="icon?.includes('.svg' || '.png' || '.jpg'); else divImg" alt="Menu Image" src="{{icon}}">
    <ng-template #divImg>
      <div class="{{icon}}" [ngStyle]="{ width: iconSize + 'px', height: iconSize + 'px' }"></div>
    </ng-template>

  </div>
  <div class="menu-text flex-basis-50" [innerHTML]="text">

  </div>
</div>
