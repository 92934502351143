import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IPairDetail } from '../../../shared/models/pair-detail.model';
import { RampViewMemoComponent } from '../../../shared/components/ramp-view-memo/ramp-view-memo.component';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../../../services/user.service';
import { firstValueFrom } from 'rxjs';
import { ChatDialogComponent } from '../../../shared/components/chat-dialog/chat-dialog.component';
import { IUser } from '../../../shared/models/user.model';
import { SelectUserToChatComponent } from '../../../shared/components/select-user-to-chat/select-user-to-chat.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-turnarounds-sub-menu',
  templateUrl: './turnarounds-sub-menu.component.html',
  styleUrls: ['./turnarounds-sub-menu.component.scss']
})
export class TurnaroundsSubMenuComponent implements OnInit, OnChanges {

  @Input() selectedPair: IPairDetail;
  @Output() backClicked = new EventEmitter();
  viewMemoModal: RampViewMemoComponent;
  faLeftArrow = faArrowLeft;
  isNewMessageDisabled = false;
  noUsersAlert = false;
  availableUsers: IUser[];
  location: string;

  constructor(private modalService: NgbModal, private userService: UserService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.selectedPair && this.viewMemoModal) {
      this.viewMemoModal.pair = this.selectedPair;
    }
  }

  openViewMemo() {
    const modalRef = this.modalService.open(RampViewMemoComponent, {size: 'lg', centered: true});

    const modal: RampViewMemoComponent = modalRef.componentInstance;
    modal.pair = this.selectedPair;
    this.viewMemoModal = modal;
    // modalRef.componentInstance.viewType = ViewTypes.RAMP_VIEW_MEMO;
    // const arrivalLegKey = this.selectedPair ? this.selectedPair.arrivalLeg?.legKey ? this.selectedPair.arrivalLeg?.legKey : null : null;
    // const departureLegKey = this.selectedPair ? this.selectedPair.departureLeg?.legKey ? this.selectedPair.departureLeg?.legKey : null : null;
    // const arrivalMemo = this.selectedPair ? this.selectedPair.arrivalLeg ? this.selectedPair.arrivalMemos ?
    //   this.selectedPair.arrivalMemos['RAMP_AGENT'] : null : null : null;
    // const departureMemo = this.selectedPair ? this.selectedPair.departureLeg ? this.selectedPair.departureMemos ?
    //   this.selectedPair.departureMemos['RAMP_AGENT'] : null : null : null;

    // modalRef.componentInstance.data = {
    //   arrivalLegKey,
    //   arrivalMemo,
    //   departureLegKey,
    //   departureMemo
    // };
  }

  get newMessageDisabled() {
    return this.isNewMessageDisabled;
  }

  async openViewPairChat() {

    if (!this.selectedPair || (!this.selectedPair.arrivalLeg && !this.selectedPair.departureLeg)) {
      return;
    }
    this.isNewMessageDisabled = true;

    this.location = this.selectedPair.arrivalLeg ?
      this.selectedPair.arrivalLeg.arrivalStation : this.selectedPair.departureLeg.departureStation;

    this.availableUsers = await firstValueFrom(this.userService.searchUsers({ location: this.location, role: ["DUTY_MANAGER", "RAMP_AGENT"], isActive: true }));
    const currentUserIndex = this.availableUsers.findIndex((user) => user.id === this.userService.userSubject.value?.id);
    this.availableUsers.splice(currentUserIndex, 1);
    if (!this.availableUsers?.length) {
      this.noUsersAlert = true;
      setTimeout(() =>  {
        this.noUsersAlert = false;
        this.isNewMessageDisabled = false;
      }, 5000);
      return;
    }

    if (this.availableUsers?.length > 1) {
      this.isNewMessageDisabled = false;
      const modalRef = this.modalService.open(SelectUserToChatComponent, {size: 'lg', centered: true});
      const modal = modalRef.componentInstance as SelectUserToChatComponent;
      modal.userList = this.availableUsers;
      modalRef.dismissed.pipe(take(1)).subscribe((result) => {
        if (result && result?.id) {
          this.openChat(result);
        }
      });
      return;
    }
    this.openChat(this.availableUsers[0]);

    this.isNewMessageDisabled = false;
  }

  openChat(user: IUser) {
    const modalRef = this.modalService.open(ChatDialogComponent, {size: 'lg', centered: true});
    const modal = modalRef.componentInstance as ChatDialogComponent;
    modal.title = `${user.firstname} ${user.lastname} (${this.location})`;
    modal.data = this.selectedPair;
    modal.user = user;
  }

}
