import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastService } from './toast.service';
import { environment } from '../../environments/environment';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { IResponse } from '../shared/models/IResponse.model';
import { ISimpleData } from '../shared/models/simpleData.model';
import { tap } from 'rxjs/operators';
import { IGeneralSettingsModel } from '../shared/models/general-settings.model';

@Injectable({
  providedIn: 'root'
})
export class GeneralSettingsService {

  generalSettings: BehaviorSubject<IGeneralSettingsModel> = new BehaviorSubject<IGeneralSettingsModel>({
    dateFormat: 'DD/MM/YYYY'
  });
  delayCodes: { [id: number]: ISimpleData } = {};

  constructor(private httpClient: HttpClient, private toastService: ToastService) {
    this.getGeneralSettings().subscribe(() => {});
  }

  getDelayCodes(): Observable<ISimpleData[]> {
    return this.httpClient.post(`${environment.api_base_url}delay-codes/search`, { isActive: true }, {}).pipe(
      map((item: IResponse<ISimpleData[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }


  deleteDelayCode(id: number) {
      return this.httpClient.delete(`${environment.api_base_url}delay-codes/${id}`, {}).pipe(
        map((item: IResponse<ISimpleData>) => {
          if(item?.isError){
            console.log(item.message);
          }
          return item?.data || {};
        }),
        catchError((error) => {
          this.toastService.showError("Error deleting delay code");
          return of({});
        })
      );
  }

  saveDelayCode(delayCode: ISimpleData): Observable<ISimpleData> {
    let obs;
    if (delayCode.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}delay-codes/${delayCode.id}`, delayCode, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}delay-codes/create`, delayCode, {})
    }
    return obs.pipe(
      map((item: IResponse<ISimpleData>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error saving delay code");
        return of({});
      })
    );
  }

  getPassengerClasses(): Observable<ISimpleData[]> {
    return this.httpClient.post(`${environment.api_base_url}passenger-classes/search`, { isActive: true }, {}).pipe(
      map((item: IResponse<ISimpleData[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }


  deletePassengerClass(id: number) {
    return this.httpClient.delete(`${environment.api_base_url}passenger-classes/${id}`, {}).pipe(
      map((item: IResponse<ISimpleData>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error deleting passenger class");
        return of({});
      })
    );
  }

  savePassengerClass(passengerClass: ISimpleData): Observable<ISimpleData> {
    let obs;
    if (passengerClass.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}passenger-classes/${passengerClass.id}`, passengerClass, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}passenger-classes/create`, passengerClass, {})
    }
    return obs.pipe(
      map((item: IResponse<ISimpleData>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error saving passenger class");
        return of({});
      })
    );
  }

  getFlightServiceTypes(): Observable<ISimpleData[]> {
    return this.httpClient.post(`${environment.api_base_url}flight-service-types/search`, { isActive: true }, {}).pipe(
      map((item: IResponse<ISimpleData[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }


  deleteFlightServiceType(id: number) {
    return this.httpClient.delete(`${environment.api_base_url}flight-service-types/${id}`, {}).pipe(
      map((item: IResponse<ISimpleData>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error deleting flight service type");
        return of({});
      })
    );
  }

  saveFlightServiceType(flightServiceType: ISimpleData): Observable<ISimpleData> {
    let obs;
    if (flightServiceType.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}flight-service-types/${flightServiceType.id}`, flightServiceType, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}flight-service-types/create`, flightServiceType, {})
    }
    return obs.pipe(
      map((item: IResponse<ISimpleData>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error saving flight service type");
        return of({});
      })
    );
  }

  getMeasurementUnits(): Observable<ISimpleData[]> {
    return this.httpClient.post(`${environment.api_base_url}measurement-units/search`, { isActive: true }, {}).pipe(
      map((item: IResponse<ISimpleData[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }


  deleteMeasurementUnit(id: number): Observable<boolean> {
    return this.httpClient.delete(`${environment.api_base_url}measurement-units/${id}`, {}).pipe(
      map((item: IResponse<boolean>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || false;
      }),
      catchError((error) => {
        this.toastService.showError("Error deleting measurement unit");
        return of(false);
      })
    );
  }

  saveMeasurementUnit(measurementUnit: ISimpleData): Observable<ISimpleData> {
    let obs;
    if (measurementUnit.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}measurement-units/${measurementUnit.id}`, measurementUnit, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}measurement-units/create`, measurementUnit, {})
    }
    return obs.pipe(
      map((item: IResponse<ISimpleData>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error saving measurement unit");
        return of({});
      })
    );
  }

  // getAirlineDesignators(): Observable<IAirlineDesignator[]> {
  //   return this.httpClient.post(`${environment.api_base_url}airline-designators/search`, { isActive: true }, {}).pipe(
  //     map((item: IResponse<IAirlineDesignator[]>) => {
  //       if(item?.isError){
  //         console.log(item.message);
  //       }
  //       return item?.data || [];
  //     }),
  //     catchError((error) => {
  //       console.log(error);
  //       return of([]);
  //     })
  //   );
  // }


  // deleteAirlineDesignator(id: number) {
  //   return this.httpClient.delete(`${environment.api_base_url}airline-designators/${id}`, {}).pipe(
  //     map((item: IResponse<IAirlineDesignator>) => {
  //       if(item?.isError){
  //         console.log(item.message);
  //       }
  //       return item?.data || {};
  //     }),
  //     catchError((error) => {
  //       this.toastService.showError("Error deleting airline code");
  //       return of({});
  //     })
  //   );
  // }

  // saveAirlineDesignator(airlineDesignator: IAirlineDesignator): Observable<IAirlineDesignator> {
  //   let obs;
  //   if (airlineDesignator.id) {
  //     obs = this.httpClient.patch(`${environment.api_base_url}airline-designators/${airlineDesignator.id}`, airlineDesignator, {})
  //   } else {
  //     obs = this.httpClient.post(`${environment.api_base_url}airline-designators/create`, airlineDesignator, {})
  //   }
  //   return obs.pipe(
  //     map((item: IResponse<IAirlineDesignator>) => {
  //       if(item?.isError){
  //         console.log(item.message);
  //       }
  //       return item?.data || {};
  //     }),
  //     catchError((error) => {
  //       this.toastService.showError("Error saving airline code");
  //       return of({});
  //     })
  //   );
  // }

  getGeneralSettings(): Observable<IGeneralSettingsModel[]> {
    return this.httpClient.post(`${environment.api_base_url}general-settings/search`, { isActive: true }, {}).pipe(
      map((item: IResponse<IGeneralSettingsModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      }),
      tap((value: ISimpleData[]) => {
        if (value.length) {
          this.generalSettings.next(value[0]);
        }
      })
    );
  }


  // deleteGeneralSettings(id: number) {
  //   return this.httpClient.delete(`${environment.api_base_url}general-settings/${id}`, {}).pipe(
  //     map((item: IResponse<IGeneralSettingsModel>) => {
  //       if(item?.isError){
  //         console.log(item.message);
  //       }
  //       return item?.data || {};
  //     }),
  //     catchError((error) => {
  //       this.toastService.showError("Error deleting general settings");
  //       return of({});
  //     })
  //   );
  // }

  // saveGeneralSettings(generalSettings: IGeneralSettingsModel): Observable<IGeneralSettingsModel> {
  //   let obs;
  //   if (generalSettings.id) {
  //     obs = this.httpClient.patch(`${environment.api_base_url}general-settings/${generalSettings.id}`, generalSettings, {})
  //   } else {
  //     obs = this.httpClient.post(`${environment.api_base_url}general-settings/create`, generalSettings, {})
  //   }
  //   return obs.pipe(
  //     map((item: IResponse<IGeneralSettingsModel>) => {
  //       if(item?.isError){
  //         console.log(item.message);
  //       }
  //       return item?.data || {};
  //     }),
  //     catchError((error) => {
  //       this.toastService.showError("Error saving general settings");
  //       return of({});
  //     })
  //   );
  // }

  // saveTurnaroundThreshold(generalSettings: IGeneralSettingsModel): Observable<IGeneralSettingsModel> {
  //   let obs;
  //   if (generalSettings.id) {
  //     obs = this.httpClient.patch(`${environment.api_base_url}general-settings/${generalSettings.id}`, generalSettings, {})
  //   } else {
  //     obs = this.httpClient.post(`${environment.api_base_url}general-settings/create`, generalSettings, {})
  //   }
  //   return obs.pipe(
  //     map((item: IResponse<IGeneralSettingsModel>) => {
  //       if(item?.isError){
  //         console.log(item.message);
  //       }
  //       return item?.data || {};
  //     }),
  //     catchError((error) => {
  //       this.toastService.showError("Error saving turnaround threshold");
  //       return of({});
  //     })
  //   );
  // }

}
