import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TurnaroundStatus } from '../../../shared/constants/turnaround-status.constants';
import { FunctionService } from '../../../services/function.service';
import { take, takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs';
import { TurnaroundService } from 'src/app/services/turnaround.service';
import { IPairDetail } from '../../../shared/models/pair-detail.model';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as dayjs from 'dayjs';
import { GeneralSettingsService } from 'src/app/services/general-settings.service';
import { convertTimestamps } from '../../../shared/utils/convert-timestamps';
import { RoutableModel } from '../../../shared/models/routable.model';
import { RampViewMemoComponent } from '../../../shared/components/ramp-view-memo/ramp-view-memo.component';
import { SelectUserToChatComponent } from '../../../shared/components/select-user-to-chat/select-user-to-chat.component';
import { IUser } from '../../../shared/models/user.model';
import { ChatDialogComponent } from '../../../shared/components/chat-dialog/chat-dialog.component';
import { PairService } from '../../../services/pair.service';


@Component({
  selector: 'app-turnarounds-pair-detail',
  templateUrl: './turnarounds-pair-detail.component.html',
  styleUrls: ['./turnarounds-pair-detail.component.scss']
})
export class TurnaroundsPairDetailComponent implements OnInit, OnDestroy, RoutableModel {

  @Input() pair: IPairDetail;
  pairId: number;
  backUrl: string = '/turnarounds';
  canGoBack: boolean = true;
  pageTitle: string = 'Loading pair...';
  activeId = 1;
  isNewMessageDisabled = false;
  noUsersAlert = false;
  availableUsers: IUser[];
  location: string;
  isFirestore: boolean;

  form: FormGroup;
  user: any;
  showAddInfo = true;
  turnaroundStatus = TurnaroundStatus;
  groundTime: number;
  isBusy = false;

  arrivalDelayInMinutes = 0;
  arrivalNumberOfDelays = 0;
  delayCodes = {};
  departureDelayInMinutes = 0;
  departureNumberOfDelays = 0;

  unsubscribe$ = new Subject();

  constructor(
    private functionService: FunctionService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private userService: UserService,
    private generalSettingsService: GeneralSettingsService,
    private turnaroundService: TurnaroundService,
    private angularFirestore: AngularFirestore,
    private pairService: PairService,
    private router: Router,
    private route: ActivatedRoute) {
    this.pairId = Number((this.route.params as BehaviorSubject<Record<string,any>>).value.id);
    if (!this.pairId || this.pairId < 0) {
      this.router.navigateByUrl('/turnarounds');
      return;
    }
    this.isFirestore = (this.route.url as BehaviorSubject<UrlSegment[]>).value[0].path.toLowerCase() === "turnarounds";
    if (!this.isFirestore) {
      this.backUrl = '/all-flights';
      this.pairService.getPairDetail(this.pairId).subscribe((pairDetails) => {
        if (!pairDetails) {
          this.router.navigateByUrl('/all-flights');
          return;
        }
        this.pair = pairDetails;
        this.pageTitle = this.pair.arrivalLeg?.acRegistration || 'No Registration';
        this.createForm();
        if (this.pair.departureLeg.tod && this.pair.arrivalLeg?.toa) {
          this.groundTime = dayjs.utc(this.pair.departureLeg?.tod).diff(dayjs.utc(this.pair.arrivalLeg?.toa), 'minutes');
        }
        this.getDelays();
        this.showAddInfo = this.pair.turnaround?.currentState === this.turnaroundStatus.UPCOMING || this.pair.turnaround === null;
      });
    }
  }

  get username(): string {
    return this.user ? `${this.user.firstname} ${this.user.lastname}` : 'unknown';
  }

  ngOnInit(): void {
    if (!this.pairId) {
      return;
    }
    this.getUser();
    if (this.isFirestore) {
      this.angularFirestore.collection('pairs').doc(this.pairId.toString()).valueChanges().pipe(takeUntil(this.unsubscribe$)).subscribe((change: any) => {
        if (!change) {
          this.router.navigateByUrl('/turnarounds');
          return;
        }
        convertTimestamps(change);
        this.pair = change;
        this.pageTitle = this.pair.arrivalLeg?.acRegistration;
        this.createForm();
        this.groundTime = (this.pair.departureLeg?.tod?.getTime() - this.pair.arrivalLeg?.toa?.getTime()) / 1000 / 60;
        this.getDelays();
        this.showAddInfo = this.pair.turnaround?.currentState === this.turnaroundStatus.UPCOMING || this.pair.turnaround === null;
      });
      return;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  createForm() {
    this.form = this.fb.group({
      time: [0, Validators.compose([Validators.required, Validators.pattern('[^0]+\\d*')])],
      reason: ['', Validators.required]
    });
  }

  async getDelays() {
    const delayCodes = await firstValueFrom(this.generalSettingsService.getDelayCodes());
    this.delayCodes = {};
    for (const code of delayCodes) {
      this.delayCodes[code.id] = code
    }
    this.arrivalNumberOfDelays = (Object.values(this.pair?.arrivalLeg.arrivalDelays ?? {}).filter((delay) => this.delayCodes[delay.delayCodeId].code !== '-'))?.length ?? 0;
    if(this.arrivalNumberOfDelays)
    {
      this.arrivalDelayInMinutes = 0;
      for (const delay of Object.values(this.pair?.arrivalLeg.arrivalDelays ?? {})) {
        this.arrivalDelayInMinutes += delay.minutes;
      }
    }
    this.departureNumberOfDelays =(Object.values(this.pair?.departureLeg?.departureDelays ?? {}).filter((delay) => this.delayCodes[delay.delayCodeId].code !== '-'))?.length ?? 0;
    if(this.departureNumberOfDelays)
    {
      this.departureDelayInMinutes = 0;
      for (const delay of Object.values(this.pair?.departureLeg.departureDelays ?? {})) {
        this.departureDelayInMinutes += delay.minutes;
      }
    }
  }

  getUser() {
    this.userService.getUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => this.user = user);
  }

  openExtendForm(content: any) {
    console.log('initial extend button was clicked');
    this.open(content);
  }

  open(content) {
    this.modalService.open(content, {centered: true, ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      const closeResult = `Closed with: ${result}`;
      console.log('closeResult', closeResult);
      this.submitClicked();
    }, () => {
      const closeResult = `Dismissed`;
      console.log('closeResult', closeResult);
    });
  }

  submitClicked() {
    // create extend turnaround request
    const formValue = this.form.value;
    this.isBusy = true;
    this.turnaroundService.extendTurnaroundRequest(this.pair.id, formValue.time, formValue.reason).subscribe((result) => {
      console.log(result);
      this.reEnableButtons();
    });
  }

  reEnableButtons() {
    // Keep buttons disabled for an extra second to prevent spamming and doing multiple API requests (Because it takes a while for data to be uploaded to firestore, and for the user to download latest data from firestore)
    setTimeout(() => this.isBusy = false, 1000);
  }

  startTurnaround() {
    if (!this.pair) {
      console.log('could not start turnaround because referring pair is empty!');
      return;
    }
    this.isBusy = true;
    this.turnaroundService.startTurnaround(+this.pair.id).subscribe(result => {
      this.reEnableButtons();
      console.log('Start turnaround result:', result);
    });
  }

  pauseTurnaround() {
    if (!this.pair) {
      console.log('could not pause turnaround because referring pair is empty!');
      return;
    }
    this.isBusy = true;

    this.turnaroundService.pauseTurnaround(+this.pair.id).subscribe(result => {
      this.reEnableButtons();
      console.log('Pause turnaround result:', result);
    });
  }

  resumeTurnaround() {
    if (!this.pair) {
      console.log('could not resume turnaround because referring pair is empty!');
      return;
    }
    this.isBusy = true;
    this.turnaroundService.resumeTurnaround(+this.pair.id).subscribe(result => {
      this.reEnableButtons();
      console.log('Resume turnaround result:', result);
    });
  }

  finishTurnaround() {
    if (!this.pair) {
      console.log('could not finish turnaround because referring pair is empty!');
      return;
    }
    this.isBusy = true;
    this.turnaroundService.finishTurnaround(+this.pair.id).subscribe(result => {
      this.reEnableButtons();
      console.log('Finish turnaround result:', result);
    });
  }

  calcDateDifferenceInDays(date1: Date, date2: Date, tz: string): number {
    if (!date1 || !date2) {
      return 0;
    }

    let d1 = dayjs(date1);
    let d2 = dayjs(date2);
    if (d1 && d2 && d1.isValid() && d2.isValid()) {
      if (tz && tz.length === 5) {
        const hours = Number(tz.slice(1, 3));
        const minutes = Number(tz.slice(3, 5)) + hours * 60;
        d1 = d1.utcOffset(minutes);
        d2 = d2.utcOffset(minutes);
      } else if (tz && tz.length === 6) {
        const hours = Number(tz.slice(1, 3));
        const minutes = Number(tz.slice(4, 6)) + hours * 60;
        d1 = d1.utcOffset(minutes);
        d2 = d2.utcOffset(minutes);
      }
      d1 = d1.startOf('day');
      d2 = d2.startOf('day');
      return Math.abs(d2.diff(d1, 'd'));
    }
    return 0;
  }

  calcDateDifferenceString(date1: Date, date2: Date, tz: string): string {
    const res = this.calcDateDifferenceInDays(date1, date2, tz);
    if (res === 0) {
      return '';
    }
    const sign = dayjs.utc(date1).isAfter(date2) ? '-' : '+';
    return sign + res;
  }
  openViewMemo() {
    const modalRef = this.modalService.open(RampViewMemoComponent, {size: 'lg', centered: true});
    const modal: RampViewMemoComponent = modalRef.componentInstance;
    modal.pair = this.pair;
    modalRef.dismissed.pipe(take(1)).subscribe((result) => {
      if (!this.isFirestore && result === 'refresh') {
        this.pairService.getPairDetail(this.pairId).subscribe((pairDetails) => {
          if (!pairDetails) {
            this.router.navigateByUrl('/all-flights');
            return;
          }
          Object.assign(this.pair, pairDetails);
        });
      }
    });
  }

  async openViewPairChat() {

    if (!this.pair || (!this.pair.arrivalLeg && !this.pair.departureLeg)) {
      return;
    }
    this.isNewMessageDisabled = true;

    this.location = this.pair.arrivalLeg ?
      this.pair.arrivalLeg.arrivalStation : this.pair.departureLeg.departureStation;

    this.availableUsers = await firstValueFrom(this.userService.searchUsers({ location: this.location, role: ["DUTY_MANAGER", "RAMP_AGENT"], isActive: true }));
    const currentUserIndex = this.availableUsers.findIndex((user) => user.id === this.userService.userSubject.value?.id);
    this.availableUsers.splice(currentUserIndex, 1);
    if (!this.availableUsers?.length) {
      this.noUsersAlert = true;
      setTimeout(() =>  {
        this.noUsersAlert = false;
        this.isNewMessageDisabled = false;
      }, 3000);
      return;
    }

    if (this.availableUsers?.length > 1) {
      this.isNewMessageDisabled = false;
      const modalRef = this.modalService.open(SelectUserToChatComponent, {size: 'lg', centered: true});
      const modal = modalRef.componentInstance as SelectUserToChatComponent;
      modal.userList = this.availableUsers;
      modalRef.dismissed.pipe(take(1)).subscribe((result) => {
        if (result && result?.id) {
          this.openChat(result);
        }
      });
      return;
    }
    this.openChat(this.availableUsers[0]);

    this.isNewMessageDisabled = false;
  }

  get newMessageDisabled() {
    return this.isNewMessageDisabled;
  }

  openChat(user: IUser) {
    const modalRef = this.modalService.open(ChatDialogComponent, {size: 'lg', centered: true});
    const modal = modalRef.componentInstance as ChatDialogComponent;
    modal.title = `${user.firstname} ${user.lastname}`;
    modal.data = this.pair;
    modal.user = user;
  }
}
