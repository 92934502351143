import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { ToastService } from './toast.service';
import { ILegsModel } from '../shared/models/legs.model';
import { Searchable } from '../shared/utils/searchable.type';

@Injectable({
  providedIn: 'root'
})
export class LegService {

  constructor(private httpClient: HttpClient, private toastService: ToastService) { }

  getLegs(filter: Searchable<ILegsModel> = { isActive: true }): Observable<ILegsModel[]> {
    return this.httpClient.post(`${environment.api_base_url}legs/search`, filter, {}).pipe(
      map((item: IResponse<ILegsModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }
}
