import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisH, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { IChatMessageModel } from '../../../models/chat-message.model';
import { IUser } from '../../../models/user.model';
import { ChatService } from '../../../../services/chat.service';
import { EditMessageDialog } from '../../edit-message-dialog/edit-message-dialog.component';

@Component({
  selector: 'app-message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.scss']
})
export class MessageItemComponent implements OnInit {

  @Input() ownMessage: boolean;
  @Input() message: IChatMessageModel;
  @Input() user: IUser;
  @Output() editClicked = new EventEmitter();
  @Input() container: HTMLElement;
  image = './content/images/avatar2.png';
  thumbsUpIcon: IconDefinition = faThumbsUp;
  ellipsisIcon = faEllipsisH;

  constructor(private chatService: ChatService, private modalService: NgbModal) { }

  ngOnInit() {
  }

  editMessage() {
    const dialogInst = this.modalService.open(EditMessageDialog, {
      centered: true,
      backdrop: 'static',
      container: this.container,
    });
    const dialog = dialogInst.componentInstance as EditMessageDialog;
    dialog.message = this.message;
  }

  deleteMessage() {
    this.chatService.deleteMessage(this.message.id).subscribe((result) => {
      if (result) {
        this.message.isActive = false;
      }
    })
  }
}
