import { Component, OnInit } from '@angular/core';
import { RoutableModel } from '../../shared/models/routable.model';

@Component({
  selector: 'app-faq-page',
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.scss']
})
export class FaqPageComponent implements OnInit, RoutableModel {
  backUrl: string = null;
  canGoBack: boolean = true;
  pageTitle: string = 'FAQs';

  constructor() { }

  ngOnInit(): void {
  }

}
