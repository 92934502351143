<div class="turnaround-time-container dark-blue roboto-font">
  <span class="fw-bold">Turnaround time:</span>
  <div class="times-container">
    <div class="time-container-40 text-center">
      <span class="fw-bold">{{groundTimeInMinutes | minutesToTime}}</span>
    </div>
    <div class="time-container-40 text-center">
      <span *ngIf="currentRequest?.extensionInMinutes">+ {{currentRequest.extensionInMinutes | minutesToTime}}</span>
    </div>
    <div class="text-center">
      <!-- <div class="circle-container" [ngClass]="{'bg-success': animationState !== 'stop' && currentRequest?.isAccepted, 'bg-danger': animationState !== 'stop' && currentRequest?.isAccepted === false}">
        <span *ngIf="currentRequest && (currentRequest.isAccepted === null || currentRequest.isAccepted === undefined)"
              class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span *ngIf="animationState !== 'stop' && currentRequest?.isAccepted"
              class="demo-icon icon-icons8-checkmark spinner-border-sm text-white font-weight-bold" role="status"
              aria-hidden="true"></span>
        <span *ngIf="animationState !== 'stop' && currentRequest?.isAccepted === false"
              class="demo-icon icon-icons8-delete spinner-border-sm text-white font-weight-bold" role="status"
              aria-hidden="true"></span>
      </div> -->

      <div class="circle-container dark-blue" [ngClass]="{'green-bg': currentRequest?.isAccepted, 'bg-danger': currentRequest?.isAccepted === false}">
        <span *ngIf="currentRequest && (currentRequest.isAccepted === null || currentRequest.isAccepted === undefined)"
              class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span *ngIf="currentRequest?.isAccepted"
              class="demo-icon icon-icons8-checkmark spinner-border-sm text-white fw-bold" role="status"
              aria-hidden="true"></span>
        <span *ngIf="currentRequest?.isAccepted === false"
              class="demo-icon icon-icons8-delete spinner-border-sm text-white fw-bold" role="status"
              aria-hidden="true"></span>
      </div>
    </div>
  </div>
</div>
