import {Component, Input, OnInit} from '@angular/core';
import { faHome } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-tabbar-item',
  templateUrl: './tabbar-item.component.html',
  styleUrls: ['./tabbar-item.component.scss']
})
export class TabbarItemComponent implements OnInit {

  @Input() routerLink: string;
  @Input() title: string;
  @Input() icon: string;
  @Input() iconSize: number = 30;
  @Input() indicatorText?: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
