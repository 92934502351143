import { Component, OnInit } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../services/user.service';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UserRoles } from '../../constants/user-roles.constants';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  userRoles = UserRoles;
  constructor(private offcanvasService: NgbOffcanvas, public userService: UserService, private router: Router, private auth: AngularFireAuth) {
  }

  ngOnInit(): void {
  }

  closeUserCard() {
    this.offcanvasService.dismiss();
  }

  goToUnderConstruction() {
    this.closeUserCard();
    this.router.navigateByUrl('/under-construction');
  }

  logout() {
    this.offcanvasService.dismiss();
    this.auth.signOut().then(() => {
      this.router.navigateByUrl('/login');
      this.userService.userSubject.next(null);
    });
  }

  protected readonly UserRoles = UserRoles;

  goToProfile() {
    this.closeUserCard();
    const currentStrategy = this.router.routeReuseStrategy.shouldReuseRoute;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(['contact',this.userService.userSubject.value.id], {skipLocationChange: false, queryParams: {}}).then(() => {
      this.router.routeReuseStrategy.shouldReuseRoute = currentStrategy;
    });
    // this.router.navigateByUrl('/', {skipLocationChange: true})
    //   .then(()=>this.router.navigate(['contact',this.userService.userSubject.value.id]));
  }
}
