<div class="modal-header">
  <h4 class="modal-title">{{title}}

    <div *ngIf="showInfo" class="ms-2 mini-icon-container rounded-circle flex" [ngClass]="{'isActive': showInfoActive}" (click)="infoClicked()">
      <i class="demo-icon mini-icon avitium-color">i</i>
    </div>
  </h4>

  <button type="button" class="btn-close" aria-label="Close" (click)="closeModal('Cross click')"></button>
</div>
<div class="modal-body">
  <ng-container [ngSwitch]="viewType">

<!--    <ng-container *ngSwitchCase="viewTypes.RAMP_VIEW_MEMO">-->
<!--      <app-ramp-view-memo [data]="data" (closeModalAction)="closeModal($event)"></app-ramp-view-memo>-->
<!--    </ng-container>-->

    <ng-container *ngSwitchCase="viewTypes.RAMP_VIEW_PAIR_CHAT">
      <app-ramp-view-pair-chat [pair]="data" [showPairInfoControl]="showInfoActive" (closeModalAction)="closeModal($event)"></app-ramp-view-pair-chat>
    </ng-container>

  </ng-container>
</div>
