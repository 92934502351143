import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MessagesComponent} from './messages.component';
import {MessageGroupListComponent} from './message-group-list/message-group-list.component';
import {MessageGroupListItemComponent} from './message-group-list/message-group-list-item/message-group-list-item.component';
import {SearchBarComponent} from './search-bar/search-bar.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { MessageGroupDetailComponent } from './message-group-detail/message-group-detail.component';
import {SharedModule} from '../../shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SimpleChatContactComponent } from './simple-chat-contact/simple-chat-contact.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    MessagesComponent,
    MessageGroupListComponent,
    MessageGroupListItemComponent,
    SearchBarComponent,
    MessageGroupDetailComponent,
    SimpleChatContactComponent,
  ],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        RouterModule
    ]
})
export class MessagesModule {
}

