import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  @Input() user: any;

  form: FormGroup;

  responseError: boolean;
  isBusy = false;
  destroy$ = new Subject();

  constructor(private fb: FormBuilder, private auth: AngularFireAuth, private userService: UserService, private route: ActivatedRoute,
              private router: Router) {
    this.auth.user.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if ((user?.multiFactor as any)?.user) {
        const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.router.navigateByUrl(returnUrl);
      }
    });
  }

  ngOnInit(): void {
    this.createForm();
  }

  ngOnDestroy() {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  createForm() {
    this.form = this.fb.group({
      username: [null, Validators.required],
      password: [null, Validators.required]
    });
  }

  signIn() {
    if (this.form.invalid) {
      return;
    }
    this.isBusy = true;
    const formVal = this.form.value;

    this.auth.signInWithEmailAndPassword(formVal.username, formVal.password).then(item => {
      // console.log('sign in res:', item);

      if (item.user && item.user.uid) {
        console.log('uid:', item.user.uid);
        console.log('Login successful:', item);
        this.userService.userSubject.pipe(take(2)).subscribe((user) => {
          if (user) {
            const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
            this.router.navigateByUrl(returnUrl);
          }
        });
        this.responseError = false;
        this.userService.setUserByUid(item.user.uid);

      }

    }).catch(error => {
      console.log('sign in error:', error);
      this.responseError = true;
      this.isBusy = false;
    });
  }

}
