<div class="d-flex flex-column gap-2 canvas-container roboto-font dark-blue">
  <div class="custom-row">
    <div class="d-flex gap-3">
      <app-user-photo class="align-self-center" [user]="userService.userSubject | async" [size]="40" circleBackground="#F2F2F2" textColor="black"></app-user-photo>
      <div class="d-flex flex-column">
        <div class="poppins">{{ userService.userSubject.value?.firstname }} {{ userService.userSubject.value?.lastname }}</div>
        <div class="roboto-font">{{ userService.userSubject.value?.role | i18nSelect: userRoles.DISPLAY_TITLE_MAP }} {{userService.userSubject.value?.location}}</div>
      </div>
      <button type="button" class="btn-close ms-auto" aria-label="Close" (click)="closeUserCard()"></button>
    </div>

  </div>
  <div class="custom-row">
    <div class="d-flex gap-3"  (click)="goToProfile()">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#002060" class="bi bi-person" viewBox="0 0 16 16">
        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
      </svg>
      <div class="roboto-font">Your profile</div>
      <div class="ms-auto"><i class="demo-icon icon-icons8-forward dark-blue"></i></div>
    </div>
  </div>
  <div class="custom-row">
    <div class="d-flex gap-3" routerLink="/change-password" (click)="closeUserCard()">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#002060" class="bi bi-shield-check" viewBox="0 0 16 16">
        <path  d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"/>
        <path  d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
      </svg>
      <div class="roboto-font">Change Password</div>
      <div class="ms-auto"><i class="demo-icon icon-icons8-forward dark-blue"></i></div>
    </div>
  </div>
<!--  <div class="custom-row">-->
<!--    <div class="d-flex gap-3" (click)="goToUnderConstruction()">-->
<!--      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#002060" class="bi bi-gear" viewBox="0 0 16 16">-->
<!--        <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/>-->
<!--        <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/>-->
<!--      </svg>-->
<!--      <div class="roboto-font">Settings</div>-->
<!--      <div class="ms-auto"><i class="demo-icon icon-icons8-forward dark-blue"></i></div>-->
<!--    </div>-->
<!--  </div>-->
  <div class="custom-row">
    <div class="d-flex gap-3" routerLink="/faq" (click)="closeUserCard()">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#002060" class="bi bi-question-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
      </svg>
      <div class="roboto-font">FAQs</div>
      <div class="ms-auto"><i class="demo-icon icon-icons8-forward dark-blue"></i></div>
    </div>
  </div>
  <div class="custom-row">
    <div class="d-flex gap-3" (click)="goToUnderConstruction()">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#002060" class="bi bi-info-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
      </svg>
      <div class="roboto-font">Version</div>
      <div class="ms-auto version">V0.2</div>
    </div>
  </div>
  <div class="custom-row">
    <button class="btn button-blue roboto-font col-12" (click)="logout()">Logout</button>
  </div>
</div>
