import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { IUser } from '../../shared/models/user.model';
import { takeWhile } from 'rxjs';
import { RoutableModel } from '../../shared/models/routable.model';

@Component({
  selector: 'app-contact-data',
  templateUrl: './contact-data.component.html',
  styleUrls: ['./contact-data.component.scss']
})
export class ContactDataComponent implements OnInit, RoutableModel {
  backUrl: string = '';
  canGoBack: boolean = true;
  pageTitle: string = 'Contact Data';


  constructor(private userService: UserService) {
    userService.getUser().pipe(takeWhile((user) => user == null, true)).subscribe((user) => {
      if (user) {
        this.userService.searchUsers({ location: userService.userSubject.value?.location, isActive: true }).subscribe((users) => {
          this.userList = users.filter((user) => user.id !== this.userService.userSubject.value.id);
        });
      }
    })

  }
  userList: IUser[];

  ngOnInit(): void {
  }

}
