import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-start-turnaround',
  templateUrl: './start-turnaround.component.html',
  styleUrls: ['./start-turnaround.component.scss']
})
export class StartTurnaroundComponent implements OnInit {

  @Output() startClicked = new EventEmitter();
  @Input() disabled = false;
  constructor() { }

  ngOnInit(): void {
  }

}
