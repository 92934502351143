import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ITurnaroundControl } from '../shared/models/turnaround-control.model';
import { catchError, map, Observable, of } from 'rxjs';
import { IResponse } from '../shared/models/IResponse.model';
import { IPairTurnaroundStatesModel } from '../shared/models/pair-turnaround-states.model';
import { Searchable } from '../shared/utils/searchable.type';

@Injectable({
  providedIn: 'root'
})
export class TurnaroundService {

  constructor(private httpClient: HttpClient) { }

  startTurnaround(pairId: number) {
    const data: ITurnaroundControl = {
      pairId,
    };
    return this.httpClient.post(`${environment.api_base_url}turnaround/start`, data, { headers: {
        Authorization: '123',
      }});
  }

  pauseTurnaround(pairId: number) {
    const data: ITurnaroundControl = {
      pairId,
    };
    return this.httpClient.post(`${environment.api_base_url}turnaround/pause`, data, { headers: {
        Authorization: '123',
      }});
  }

  finishTurnaround(pairId: number) {
    const data: ITurnaroundControl = {
      pairId,
    };
    return this.httpClient.post(`${environment.api_base_url}turnaround/finish`, data, { headers: {
        Authorization: '123',
      }});
  }

  resumeTurnaround(pairId: number) {
    const data: ITurnaroundControl = {
      pairId,
    };
    return this.httpClient.post(`${environment.api_base_url}turnaround/resume`, data, { headers: {
        Authorization: '123',
      }});
  }

  extendTurnaroundRequest(pairId: number, time: number, reason: string) {
    const data: ITurnaroundControl = {
      extensionInMinutes: time,
      pairId,
      reason,
    };
    return this.httpClient.post(`${environment.api_base_url}turnaround/etd-extension/request`, data, { headers: {
        Authorization: '123',
      }});
  }

  getTurnarounds(filter: Searchable<IPairTurnaroundStatesModel> = { isActive: true }): Observable<IPairTurnaroundStatesModel[]> {
    return this.httpClient.post(`${environment.api_base_url}pair-turnaround-states/search`, filter, { headers: { authorization : '123'}}).pipe(
      map((item: IResponse<IPairTurnaroundStatesModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  // startTurnaround(pairId: number) {
  //   return this.httpClient.post(`${environment.api_base_url}pair-turnaround-states/create`, { pairId, turnaroundStateId: TurnaroundStatus.IDS[TurnaroundStatus.IN_PROGRESS] }, { headers: {
  //     Authorization: '123',
  //   }});
  // }
  //
  // pauseTurnaround(pairId: number) {
  //   return this.httpClient.post(`${environment.api_base_url}pair-turnaround-states/create`, { pairId, turnaroundStateId: TurnaroundStatus.IDS[TurnaroundStatus.PAUSED] }, { headers: {
  //       Authorization: '123',
  //     }});
  // }
  //
  // finishTurnaround(pairId: number) {
  //   return this.httpClient.post(`${environment.api_base_url}pair-turnaround-states/create`, { pairId, turnaroundStateId: TurnaroundStatus.IDS[TurnaroundStatus.FINISHED] }, { headers: {
  //       Authorization: '123',
  //     }});
  // }
  //
  // resumeTurnaround(pairId: number) {
  //   return this.httpClient.post(`${environment.api_base_url}pair-turnaround-states/create`, { pairId, turnaroundStateId: TurnaroundStatus.IDS[TurnaroundStatus.RESUMED] }, { headers: {
  //       Authorization: '123',
  //     }});
  // }
  //
  // extendTurnaroundRequest(pairId: number, time: number, reason: string) {
  //   return this.httpClient.post(`${environment.api_base_url}pair-turnaround-time-logs/create`, { pairId, timeTypeId: 3, turnaroundTimeInMinutes: time, reason, suggestedBy: this.userService.userSubject.value.id, suggestedAt: new Date(), status: StatusType.SUGGESTED } as IPairTurnaroundTimeLogsModel, { headers: {
  //       Authorization: '123',
  //     }});
  // }
}
