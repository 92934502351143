import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ChatService } from '../../../services/chat.service';
import { firstValueFrom, Subject } from 'rxjs';
import { IMyChatGroups } from '../../../shared/models/my-chat-groups.interface';
import { UserService } from '../../../services/user.service';
import { IGenericContainerObject } from '../../../shared/models/genericContainerObject.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-message-group-list',
  templateUrl: './message-group-list.component.html',
  styleUrls: ['./message-group-list.component.scss']
})
export class MessageGroupListComponent implements OnInit, OnDestroy, OnChanges {

  @Output() groupSelectionAction = new EventEmitter<IMyChatGroups>();
  @Input() searchKeyword: string;

  messageGroupKeySelection?: number;

  // New
  messageGroups: IMyChatGroups[] = [];
  myGroups: IGenericContainerObject<IMyChatGroups> = {};
  shownMessageGroups: IMyChatGroups[] = [];
  destroy$ = new Subject();

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchKeyword) {
      this.filterGroups();
    }
  }

  filterGroups() {
    if (!this.searchKeyword) {
      this.shownMessageGroups = this.messageGroups;
      return;
    }

    this.shownMessageGroups = this.messageGroups.filter((group) => group.__user__?.firstname.concat(group.__user__?.lastname).trim().toLowerCase().includes(this.searchKeyword.replace(" ", "").toLowerCase()));
  }

  constructor(private chatService: ChatService, private userService: UserService) {
  }

  ngOnInit(): void {
    this.userService.userSubject.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user) {
        firstValueFrom(this.chatService.getMyChatGroups()).then((result) => {
          this.messageGroups = result.filter((group) => group.userId !== this.userService.userSubject.value?.id);
          for (const res of result.filter((group) => group.userId === this.userService.userSubject.value?.id)) {
            this.myGroups[res.chatGroupId] = res;
          }
          this.filterGroups();
        });
      }
    });
  }

  setSelectedMessageGroup(chatGroupId: number) {
    this.messageGroupKeySelection = chatGroupId;
    const val = this.messageGroups.find((group) => group.chatGroupId === chatGroupId);
    this.groupSelectionAction.emit(val);
  }

}
